export const getSvgIcon = (
  key,
  width = 16,
  height = 16,
  fill = "#767676",
  customStyle
) => {
  let icon = null;

  switch (key) {
    case "FORUM":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_924_6794"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="11"
            height="11"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99951 4.98384H14.9995V15H4.99951V4.98384Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_924_6794)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.0065 10.4148C10.8835 10.4148 11.5565 9.31196 11.5565 7.93369C11.5565 6.7247 10.9895 6.00753 10.0065 6.00753C9.02351 6.00753 8.45651 6.7247 8.45651 7.93369C8.45651 9.31196 9.12951 10.4148 10.0065 10.4148ZM9.99951 11.4244C11.4135 11.4244 12.4995 9.81178 12.4995 7.79847C12.4995 6.03157 11.5855 4.98384 9.99951 4.98384C8.41451 4.98384 7.49951 6.03157 7.49951 7.79847C7.49951 9.81178 8.58551 11.4244 9.99951 11.4244ZM14.0065 13.9987H5.99551V13.3215C5.99551 12.7135 6.39951 11.9894 7.00651 11.9894H7.80751C8.43051 12.4862 9.19651 12.7726 9.99251 12.8047C10.7935 12.7756 11.5635 12.4922 12.1925 11.9964H13.0065C13.6145 11.9964 14.0065 12.7206 14.0065 13.3296V13.9987ZM11.9145 10.9827C11.4135 11.5076 10.7245 11.9733 9.99951 11.9843C9.27551 11.9733 8.58651 11.5076 8.08551 10.9827H6.66451C5.74451 10.9877 5.00051 11.9623 4.99951 12.8838L5.00001 15H15L14.9995 12.8838C14.9995 11.9623 14.2555 10.9877 13.3355 10.9827H11.9145Z"
              fill={fill}
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 9.99177L1.995 9.3387C1.995 8.7307 2.399 8.00651 3.007 8.00651H3.807C4.431 8.50333 5.196 8.7888 5.993 8.82185C6.332 8.80883 6.663 8.74172 6.983 8.64055V7.71303C6.674 7.88231 6.343 7.99649 6 8.0015C5.276 7.99048 4.501 7.5107 4 6.98684L2.664 6.99986C1.744 7.00386 1 7.97947 1 8.90098V11H5V10L2 9.99177Z"
            fill={fill}
          />
          <mask
            id="mask1_924_6794"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="3"
            y="1"
            width="6"
            height="7"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.50049 1H8.50049V7.44148H3.50049V1Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_924_6794)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.98349 5.87591C6.71849 6.21847 6.38349 6.43182 6.00649 6.43182C5.12949 6.43182 4.45649 5.32801 4.45649 3.94975C4.45649 2.74176 5.02349 2.02458 6.00649 2.02458C6.98949 2.02458 7.55649 2.74176 7.55649 3.94975C7.55649 4.31635 7.50649 4.66092 7.41949 4.97343H8.36249C8.44549 4.60783 8.50049 4.2232 8.50049 3.81452C8.50049 2.04862 7.58549 0.999901 6.00049 0.999901C4.41449 0.999901 3.50049 2.04862 3.50049 3.81452C3.50049 5.82883 4.58549 7.44148 5.99949 7.44148C6.35249 7.44148 6.68349 7.34032 6.98349 7.15902V6.9066V5.87591Z"
              fill={fill}
            />
          </g>
        </svg>
      );

      break;
    case "RAISE_FEATURE_REQUEST":
      icon = (
        <svg
          width={width}
          height={height}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2109_1218)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9 14H7L6.64 12.45L5.82 12.11L5.12 12.53L4.42 12.95L3.74 12.26L3.05 11.58L3.87 10.21L3.88 10.2L3.89 10.18L3.79 9.96L3.7 9.74L3.62 9.55L3.54 9.36L2.76 9.16L1.97 8.97V7.03L2.74 6.84L3.51 6.64H3.54L3.7 6.2L3.86 5.77L3.84 5.75L3.45 5.09L3.05 4.42L4.42 3.05L5.09 3.46L5.76 3.86L6.65 3.49L6.84 2.73L7.03 1.98H8.97L9.16 2.73L9.36 3.49L10.24 3.85L10.91 3.45L11.58 3.05L12.26 3.74L12.95 4.42L12.13 5.79L12.28 6.21L12.44 6.64L13.23 6.83L14 7V9H15V6.26L14.26 6.08L13.47 5.88L13.24 5.83L13.78 4.93L14.18 4.27L13.64 3.73L12.95 3.05L12.27 2.36L11.72 1.82L11.07 2.22L10.41 2.62L10.18 2.76L10.11 2.49L9.92 1.73L9.73 1H6.26L6.08 1.74L5.89 2.5L5.83 2.76L5.6 2.62L4.93 2.22L4.28 1.82L3.73 2.36L2.36 3.73L1.82 4.27L2.21 4.93L2.61 5.59L2.76 5.83L2.51 5.89L1.74 6.08L1 6.27V7.03V8.97V9.74L1.74 9.92L2.52 10.11L2.76 10.17L2.21 11.08L1.82 11.73L2.36 12.27L3.05 12.95L3.73 13.64L4.27 14.18L4.93 13.78L5.62 13.37L5.83 13.24L6.08 14.26L6.27 15H7.03H9V14Z"
              fill={fill}
            />
            <path d="M13 10H12V15H13V10Z" fill={fill} />
            <path d="M15 12H10V13H15V12Z" fill={fill} />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.99999 10C6.89999 10 5.99999 9.1 5.99999 8C5.99999 6.9 6.89999 6 7.99999 6C9.09999 6 9.99999 6.89 9.99999 8C9.99999 8.37 9.88999 8.7 9.71999 9H10.81C10.92 8.69 10.99 8.35 10.99 8C10.99 6.35 9.63999 5 7.98999 5C6.33999 5 4.98999 6.35 4.98999 8C4.98999 9.65 6.33999 11 7.98999 11V10H7.99999Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_2109_1218">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "INSTALL":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6796)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.49 1L7.5 8.51L5.12 6.57L4.5 7.33L8 10.04L11.5 7.33L10.9 6.67L8.49 8.53V1H7.49Z"
              fill={fill}
            />
            <path d="M14 12V14H2V12H14ZM15 11H1V15H15V11Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6796">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ABOUT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6825)">
            <path
              d="M8 2C11.31 2 14 4.69 14 8C14 11.31 11.31 14 8 14C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2ZM8 1C4.13 1 1 4.13 1 8C1 11.87 4.13 15 8 15C11.87 15 15 11.87 15 8C15 4.13 11.87 1 8 1Z"
              fill={fill}
            />
            <path d="M8.5 7H7.5V12H8.5V7Z" fill={fill} />
            <path d="M8.5 5H7.5V6H8.5V5Z" fill="white" />
            <path d="M8.5 5H7.5V6H8.5V5Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6825">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ORGANIZATION":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6798)">
            <path d="M4 12V14H2V12H4ZM5 11H1V15H5V11Z" fill={fill} />
            <path d="M9 12V14H7V12H9ZM10 11H6V15H10V11Z" fill={fill} />
            <path d="M14 12V14H12V12H14ZM15 11H11V15H15V11Z" fill={fill} />
            <path
              d="M8.5 6H7.5V8H3H2V9V10H3V9H7.5V10H8.5V9H13V10H14V9V8H13H8.5V6Z"
              fill={fill}
            />
            <path d="M10 2V4H6V2H10ZM11 1H5V5H11V1Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6798">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "PREFERENCES":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6808)">
            <path d="M10 3H0V4H10V3Z" fill="white" />
            <path d="M10 3H0V4H10V3Z" fill={fill} />
            <path d="M11.5 2.5V3.5H15.5H11.5V4.5V2.5Z" fill="white" />
            <path d="M12 2H11V5H12V4H16V3H12V2Z" fill={fill} />
            <path d="M8 9H0V10H8V9Z" fill="white" />
            <path d="M8 9H0V10H8V9Z" fill={fill} />
            <path d="M9.5 8.5V9.5H15.5H9.5V10.5V8.5Z" fill="white" />
            <path d="M10 8H9V11H10V10H16V9H10V8Z" fill={fill} />
            <path d="M16 12H8V13H16V12Z" fill="white" />
            <path d="M16 12H8V13H16V12Z" fill={fill} />
            <path d="M7 11H6V12H0V13H6V14H7V11Z" fill={fill} />
            <path d="M16 6H4V7H16V6Z" fill="white" />
            <path d="M16 6H4V7H16V6Z" fill={fill} />
            <path d="M3 5H2V6H0V7H2V8H3V5Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6808">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "LOG_OUT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 1V15H8V14.001H2V2.001H8V1H1Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1646 5.1211L13.3086 7.5011H4.99957V8.5011H13.3286L11.2686 10.8951L11.9976 11.5001L15.0006 8.0011L11.9976 4.5001L11.1646 5.1211Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "PROFILE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 14H2V12.86C2 12.01 2.69 10.998 3.54 10.997H4.93C5.803 11.692 6.875 12.092 7.99 12.137C9.11 12.096 10.189 11.7 11.07 11.007H12.46C13.31 11.008 14 12.02 14 12.87V14ZM10.68 10.013C9.979 10.746 9.014 11.167 8 11.183C6.986 11.167 6.021 10.746 5.32 10.013H3.33C2.042 10.019 1 11.382 1 12.67V15H15V12.67C15 11.382 13.958 10.019 12.67 10.013H10.68ZM8 2.003C9.58 2.003 10.5 3.112 10.5 5.059C10.5 7.267 9.418 9.001 7.998 9.001C6.578 9.001 5.5 7.267 5.5 5.059C5.5 3.112 6.42 2.003 8 2.003ZM8 10.002C9.98 10.002 11.5 7.749 11.5 4.934C11.5 2.466 10.22 1 8 1C5.78 1 4.5 2.466 4.5 4.934C4.5 7.749 6.02 10.002 8 10.002Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "ASSETS":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 2.12L14 5.12V10.88L8 13.88L2 10.88V5.12L8 2.12ZM8 1L1 4.5V11.5L8 15L15 11.5V4.5L8 1Z"
            fill={fill}
          />
          <path
            d="M1.75 4.5L8.09 8L14.25 4.5"
            stroke={fill}
            strokeMiterlimit="10"
          />
          <path d="M8 14.55V8" stroke={fill} strokeMiterlimit="10" />
        </svg>
      );

      break;
    case "REPORTS":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6817)">
            <path
              d="M10.54 1L13 3.46V15H3V1H10.54ZM10.95 0H2V16H14V3.04L10.95 0Z"
              fill={fill}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.03 6C10.03 6 8.03 6 8.03 8C8.03 8 8.03 10 10.03 10C10.03 10 12.03 10 12.03 8H10.03V6Z"
              fill={fill}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.53 5.5V7.5H12.53C12.53 7.5 12.53 5.5 10.53 5.5Z"
              fill={fill}
            />
            <path d="M6.03 9H4.03V10H6.03V9Z" fill={fill} />
            <path d="M8.03 11H4.03V12H8.03V11Z" fill={fill} />
            <path d="M10.03 13H4.03V14H10.03V13Z" fill={fill} />
            <path
              d="M4.03 5.67L6.07 3.64L7.48 5.03L9.71 2.85"
              stroke={fill}
              strokeMiterlimit="10"
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6817">
              <rect
                width={width}
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "FAVORITES":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1L6.269 6.235L1 6.348L5.2 9.696L3.674 15L8 11.835L12.326 15L10.8 9.696L15 6.348L9.731 6.235L8 1ZM6.16 9.972L6.346 9.33L5.823 8.914L3.784 7.288L6.291 7.235L6.997 7.22L7.219 6.548L8 4.186L8.781 6.548L9.003 7.22L9.709 7.235L12.216 7.288L10.176 8.914L9.654 9.33L9.839 9.972L10.556 12.467L8.59 11.028L8 10.596L7.41 11.028L5.443 12.467L6.16 9.972Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "MODEL_LIBRARY":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6797)">
            <path d="M4.04 3V14H2V3H4.04ZM5.04 2H1V15H5.04V2Z" fill={fill} />
            <path
              d="M7.00003 2V14H5.03003V2H7.00003ZM8.00003 1H4.03003V15H8.00003V1Z"
              fill={fill}
            />
            <path
              d="M10.23 2.25L13.74 13.18L11.95 13.75L8.43999 2.82L10.23 2.25ZM10.88 1L7.17999 2.17L11.3 15L15 13.83L10.88 1Z"
              fill={fill}
            />
            <path d="M4.03001 12H2.01001V13H4.03001V12Z" fill={fill} />
            <path d="M7.04998 12H5.03998V13H7.04998V12Z" fill={fill} />
            <path d="M4.03001 4H2.01001V5H4.03001V4Z" fill={fill} />
            <path d="M7.04998 3H5.03998V4H7.04998V3Z" fill={fill} />
            <path
              d="M13.89 10.57L11.01 11.48L11.31 12.43L14.2 11.52L13.89 10.57Z"
              fill={fill}
            />
            <path
              d="M10.87 3.57L7.97998 4.48L8.28998 5.43L11.17 4.52L10.87 3.57Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6797">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "LAST_USED":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6799)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 8V8.46C2.26 11.76 5.15 14.23 8.45 13.98C11.76 13.73 14.23 10.84 13.98 7.54C13.74 4.42 11.13 2 7.99 2C6.39 2 4.85 2.64 3.72 3.78C3.63 3.87 3.56 3.94 3.49 4H6V5H2V1H2.98V3.1C2.98 3.1 2.98 3.1 2.99 3.09C2.99 3.09 3 3.08 3.01 3.07C5.73 0.320002 10.17 0.300002 12.92 3.02C15.67 5.74 15.69 10.17 12.97 12.92C11.65 14.25 9.86 14.99 7.99 14.99C4.33 14.99 1.3 12.18 1 8.54V8H2Z"
              fill={fill}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.19 10.68L7.99999 9.42V5H6.99999V9.97L6.98999 9.99L6.99999 10H7.00999L9.68999 11.55L10.19 10.68Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6799">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "STAR":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1L6.269 6.235L1 6.348L5.2 9.696L3.674 15L8 11.835L12.326 15L10.8 9.696L15 6.348L9.731 6.235L8 1ZM6.16 9.972L6.346 9.33L5.823 8.914L3.784 7.288L6.291 7.235L6.997 7.22L7.219 6.548L8 4.186L8.781 6.548L9.003 7.22L9.709 7.235L12.216 7.288L10.176 8.914L9.654 9.33L9.839 9.972L10.556 12.467L8.59 11.028L8 10.596L7.41 11.028L5.443 12.467L6.16 9.972Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "STAR_FILLED":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1L6.269 6.235L1 6.348L5.2 9.696L3.674 15L8 11.835L12.326 15L10.8 9.696L15 6.348L9.731 6.235L8 1Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "EDIT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6791)">
            <path
              d="M10.89 2.41L13.57 5.01L4.59 14H2V11.42L10.89 2.41ZM10.88 1L1 11.01V15H5L15 5L10.88 1Z"
              fill={fill}
            />
            <path d="M11.5 4.5L5 11" stroke={fill} strokeMiterlimit="10" />
            <path d="M2 11L5 14" stroke={fill} strokeMiterlimit="10" />
          </g>
          <defs>
            <clipPath id="clip0_924_6791">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CREATE_NEW":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6807)">
            <path
              d="M8.44 1H7.56V7.56H1V8.44H7.56V15H8.44V8.44H15V7.56H8.44V1Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6807">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "LIST_VIEW":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6787)">
            <path d="M15 1H6V2H15V1Z" fill="white" />
            <path d="M15 1H6V2H15V1Z" fill={fill} />
            <path d="M15 3H6V4H15V3Z" fill="white" />
            <path d="M15 3H6V4H15V3Z" fill={fill} />
            <path d="M15 8H6V9H15V8Z" fill="white" />
            <path d="M15 8H6V9H15V8Z" fill={fill} />
            <path d="M15 6H6V7H15V6Z" fill="white" />
            <path d="M15 6H6V7H15V6Z" fill={fill} />
            <path d="M15 11H6V12H15V11Z" fill="white" />
            <path d="M15 11H6V12H15V11Z" fill={fill} />
            <path d="M15 13H6V14H15V13Z" fill="white" />
            <path d="M15 13H6V14H15V13Z" fill={fill} />
            <path d="M4 2V4H2V2H4ZM5 1H1V5H5V1Z" fill={fill} />
            <path d="M4 7V9H2V7H4ZM5 6H1V10H5V6Z" fill={fill} />
            <path d="M4 12V14H2V12H4ZM5 11H1V15H5V11Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6787">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "TILE_VIEW":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 7H7V1H1V7ZM2 6H6.001V2H2V6Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 7H15V1H9V7ZM10 6H14V2H10V6Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 15H7V9H1V15ZM2 14.001H6.001V10H2V14.001Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 15H15V9H9V15ZM10 14.001H14V10H10V14.001Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "COLUMN_VIEW":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 2L0 2L0.0010004 14L16 14L16 2ZM15 2.995L15 13L11 13L11 3L15 2.995ZM10.001 2.995L10 13H6L6 3L10.001 2.995ZM5 2.995L5 13L1 13L1 3L5 2.995Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "SORTING":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6795)">
            <path d="M4 5.01H1V6.01H4V5.01Z" fill={fill} />
            <path d="M6 8.01H1V9.01H6V8.01Z" fill={fill} />
            <path d="M8 11.01H1V12.01H8V11.01Z" fill={fill} />
            <path d="M10 14.01H1V15.01H10V14.01Z" fill={fill} />
            <path d="M8.96997 2.01H7.96997V8.01H8.96997V2.01Z" fill={fill} />
            <path
              d="M10.34 3.78L10.97 3L8.48 1L7.85 1.78L10.34 3.77M9.1 1.78L8.48 1L6 2.99L6.63 3.77L9.11 1.78"
              fill={fill}
            />
            <path d="M12 11.98H13V6.01H12V11.98Z" fill={fill} />
            <path
              d="M10.63 10.21L10 10.99L12.49 12.98L13.12 12.2L10.63 10.21ZM11.86 12.21L12.49 12.99L14.97 11L14.34 10.22L11.86 12.21Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6795">
              <rect
                width="13.97"
                height="14.01"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "SEARCH":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7468 10.0398C10.7408 10.0338 10.7328 10.0318 10.7268 10.0268C12.6118 7.76778 12.3858 4.42478 10.2138 2.43978C8.04178 0.455782 4.69178 0.530782 2.61178 2.61178C0.530782 4.69178 0.455782 8.04178 2.43978 10.2138C4.42478 12.3858 7.76778 12.6118 10.0268 10.7268C10.0328 10.7328 10.0338 10.7408 10.0398 10.7468L14.2888 14.9998L14.9998 14.2908L10.7468 10.0398ZM6.50578 11.0048C4.02078 11.0048 2.00578 8.98978 2.00578 6.50478C2.00578 4.02078 4.02078 2.00578 6.50578 2.00578C8.98978 2.00578 11.0048 4.02078 11.0048 6.50478C11.0018 8.98878 8.98878 11.0018 6.50578 11.0048Z"
            fill={fill}
          />
          <mask
            id="mask0_924_6790"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="14"
            height="14"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.61217 2.61208C0.531168 4.69208 0.455168 8.04108 2.44017 10.2131C4.42417 12.3861 7.76717 12.6121 10.0272 10.7271C10.0322 10.7331 10.0342 10.7401 10.0402 10.7471L14.2882 15.0001L14.9992 14.2911L10.7472 10.0391C10.7412 10.0341 10.7332 10.0321 10.7272 10.0271C12.6122 7.76808 12.3862 4.42508 10.2132 2.44008C9.16017 1.47808 7.83117 1.00008 6.50317 1.00008C5.09217 1.00008 3.68317 1.54008 2.61217 2.61208ZM2.00617 6.50508C2.00617 4.02008 4.02017 2.00608 6.50517 2.00608C8.99017 2.00608 11.0042 4.02008 11.0042 6.50508C11.0022 8.98908 8.98917 11.0021 6.50517 11.0041C4.02017 11.0041 2.00617 8.99008 2.00617 6.50508Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_924_6790)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.000183105 15.8571H15.8572V8.2016e-05H0.000183105V15.8571Z"
              fill={fill}
            />
          </g>
        </svg>
      );

      break;
    case "EXPAND":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 5.839L7.999 11L13 5.839L12.188 5L7.999 9.324L3.812 5L3 5.839Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "IMPORT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 1V2.001V14.001V15H2H7V14.001H2V2.001H7.586L11 5.414V9H12V5.001L8 1H1Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8299 14.3823L9.68988 12.9943H14.9999V12.0003H9.66888L10.7269 10.6253L9.94988 10.0003L7.99988 12.4713L10.0469 15.0003L10.8299 14.3823Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "OPEN":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 1V2.001V14.001V15H2H14H15V9H14V14.001H2V2L6 2.001V1H1Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9999 1V2.001H13.2929L8.00189 7.291L8.70889 7.998L13.9999 2.707V5.001H15.0009V1H10.9999Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "COLLAPSE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 10.161L7.999 5L13 10.161L12.188 11L7.999 6.676L3.812 11L3 10.161Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "BUILDING":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6818)">
            <path
              d="M12 2V14H10V10H6V14H4V2H12ZM13 1H3V15H7V11H9V15H13V1Z"
              fill={fill}
            />
            <path d="M2 3H1V15H2V3Z" fill={fill} />
            <path d="M15 3H14V15H15V3Z" fill={fill} />
            <path d="M7 3H5V5H7V3Z" fill={fill} />
            <path d="M11 3H9V5H11V3Z" fill={fill} />
            <path d="M7 6H5V8H7V6Z" fill={fill} />
            <path d="M11 6H9V8H11V6Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6818">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CABINET":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6826)">
            <path
              d="M9.52 3.35L13.62 0.85L13.1 0L9 2.5L9.52 3.35ZM13.1 1H14V0H13.1V1ZM13 0V16H14V0H13ZM14 15H13.1V16H14V15ZM13.62 15.15L9.52 12.65L9 13.5L13.1 16L13.62 15.15Z"
              fill={fill}
            />
            <path d="M9 3V13H3V3H9ZM10 2H2V14H10V2Z" fill={fill} />
            <path d="M9 6H3V7H9V6Z" fill={fill} />
            <path d="M9 10H3V11H9V10Z" fill={fill} />
            <path
              d="M4.5 5C4.77614 5 5 4.77614 5 4.5C5 4.22386 4.77614 4 4.5 4C4.22386 4 4 4.22386 4 4.5C4 4.77614 4.22386 5 4.5 5Z"
              fill={fill}
            />
            <path
              d="M4.5 9C4.77614 9 5 8.77614 5 8.5C5 8.22386 4.77614 8 4.5 8C4.22386 8 4 8.22386 4 8.5C4 8.77614 4.22386 9 4.5 9Z"
              fill={fill}
            />
            <path d="M8 4H6V5H8V4Z" fill={fill} />
            <path d="M8 8H6V9H8V8Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6826">
              <rect
                width={width}
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CAMERA":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6829)">
            <path
              d="M10.46 3L12.16 5.55L12.46 6H15V13H1V6H3.54L3.84 5.55L5.54 3H10.47M11 2H5L3 5H0V14H16V5H13L11 2Z"
              fill={fill}
            />
            <path
              d="M8 7C9.1 7 10 7.9 10 9C10 10.1 9.1 11 8 11C6.9 11 6 10.1 6 9C6 7.9 6.9 7 8 7ZM8 6C6.34 6 5 7.34 5 9C5 10.66 6.34 12 8 12C9.66 12 11 10.66 11 9C11 7.34 9.66 6 8 6Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6829">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CITY":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6811)">
            <path d="M16 13H0V14H16V13Z" fill="white" />
            <path d="M16 13H0V14H16V13Z" fill={fill} />
            <path d="M10 6V13H6V6H10ZM11 5H5V14H11V5Z" fill={fill} />
            <path
              d="M2.5 9C2.78 9 3 9.22 3 9.5C3 9.74 2.83 9.91 2.67 9.97L2.5 10.03L2.33 9.97C2.16 9.91 2 9.74 2 9.5C2 9.22 2.22 9 2.5 9ZM2.5 8C1.67 8 1 8.67 1 9.5C1 10.15 1.42 10.7 2 10.91V13H3V10.91C3.58 10.7 4 10.15 4 9.5C4 8.67 3.33 8 2.5 8Z"
              fill={fill}
            />
            <path d="M14 3V13H11V3H14ZM15 2H10V14H15V2Z" fill={fill} />
            <path d="M8.5 11.5H7.5V12.5H8.5V11.5Z" fill="white" />
            <path d="M9 11H7V13H9V11Z" fill={fill} />
            <path d="M7.5 7H6.5V8H7.5V7Z" fill="white" />
            <path d="M7.5 7H6.5V8H7.5V7Z" fill={fill} />
            <path d="M9.5 7H8.5V8H9.5V7Z" fill="white" />
            <path d="M9.5 7H8.5V8H9.5V7Z" fill={fill} />
            <path d="M7.5 9H6.5V10H7.5V9Z" fill="white" />
            <path d="M7.5 9H6.5V10H7.5V9Z" fill={fill} />
            <path d="M9.5 9H8.5V10H9.5V9Z" fill="white" />
            <path d="M9.5 9H8.5V10H9.5V9Z" fill={fill} />
            <path d="M13 4H12V5H13V4Z" fill="white" />
            <path d="M13 4H12V5H13V4Z" fill={fill} />
            <path d="M13 6H12V7H13V6Z" fill="white" />
            <path d="M13 6H12V7H13V6Z" fill={fill} />
            <path d="M13 8H12V9H13V8Z" fill="white" />
            <path d="M13 8H12V9H13V8Z" fill={fill} />
            <path d="M13 10H12V11H13V10Z" fill="white" />
            <path d="M13 10H12V11H13V10Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6811">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "CLIENT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6830)">
            <path d="M6 2V14H2V2H6ZM7 1H1V15H7V1Z" fill={fill} />
            <path d="M6 6H2V7H6V6Z" fill={fill} />
            <path d="M6 8H2V9H6V8Z" fill={fill} />
            <path d="M5 10H3V11H5V10Z" fill={fill} />
            <path d="M5 12H3V13H5V12Z" fill={fill} />
            <path
              d="M4 5C4.55228 5 5 4.55228 5 4C5 3.44772 4.55228 3 4 3C3.44772 3 3 3.44772 3 4C3 4.55228 3.44772 5 4 5Z"
              fill={fill}
            />
            <path d="M14 13H7V14H14V13Z" fill={fill} />
            <path
              d="M7 10V11H15V10H7ZM14 11H15V4H14V11ZM15 5V4H7V5H15Z"
              fill={fill}
            />
            <path d="M11 11H9V14H11V11Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6830">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "FLOOR":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.04163 1L1.04163 5.001H15.0416L8.04163 1Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 10.0004H2V6.0004H1V10.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 15.0004H2V11.0004H1V15.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 14.0004H15.001V11.0004H14V14.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 10.0004H3V9.0004H1V10.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 10.0004H15.001V9.0004H13V10.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 10.0004H6.001V9.0004H4V10.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 10.0004H9V9.0004H7V10.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 10.0004H12V9.0004H10V10.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 15.0004H3V14.0004H1V15.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 15.0004H15.001V14.0004H13V15.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 15.0004H6.001V14.0004H4V15.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 15.0004H9V14.0004H7V15.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 15.0004H12V14.0004H10V15.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 10.0004H15.001V6.0004H14V10.0004Z"
            fill={fill}
          />
        </svg>
      );

      break;
    case "FRAME":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6827)">
            <path d="M13 1V15H3V1H13ZM14 0H2V16H14V0Z" fill={fill} />
            <path d="M13 4H3V5H13V4Z" fill={fill} />
            <path d="M13 8H3V9H13V8Z" fill={fill} />
            <path d="M13 12H3V13H13V12Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6827">
              <rect
                width={width}
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "MODEM":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6813)">
            <path d="M14 2V14H9V2H14ZM15 1H8V15H15V1Z" fill={fill} />
            <path
              d="M8.22 14.03L1.22 12.41L1 13.38L8 15L8.22 14.03ZM2 13.38V2.62H1V13.39H2V13.38ZM1.22 3.59L8.22 1.97L8 1L1 2.62L1.22 3.59Z"
              fill={fill}
            />
            <path
              d="M11.5 11C11.78 11 12 11.22 12 11.5C12 11.78 11.78 12 11.5 12C11.22 12 11 11.78 11 11.5C11 11.22 11.22 11 11.5 11ZM11.5 10C10.67 10 10 10.67 10 11.5C10 12.33 10.67 13 11.5 13C12.33 13 13 12.33 13 11.5C13 10.67 12.33 10 11.5 10Z"
              fill={fill}
            />
            <path d="M12 3H11V6H12V3Z" fill={fill} />
            <path d="M12 7H11V9H12V7Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6813">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "MONITOR":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6812)">
            <path d="M14 2V10H2V2H14ZM15 1H1V11H15V1Z" fill={fill} />
            <path d="M9 11H7V14H9V11Z" fill={fill} />
            <path d="M11 13H5V15H11V13Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6812">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "RACK":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6802)">
            <path d="M13 1V15H3V1H13ZM14 0H2V16H14V0Z" fill={fill} />
            <path d="M13 4H3V5H13V4Z" fill={fill} />
            <path d="M13 8H3V9H13V8Z" fill={fill} />
            <path d="M13 12H3V13H13V12Z" fill={fill} />
            <path
              d="M4.5 2C4.22 2 4 2.22 4 2.5C4 2.78 4.22 3 4.5 3C4.78 3 5 2.78 5 2.5C5 2.22 4.78 2 4.5 2Z"
              fill={fill}
            />
            <path
              d="M4.5 6C4.22 6 4 6.22 4 6.5C4 6.78 4.22 7 4.5 7C4.78 7 5 6.78 5 6.5C5 6.22 4.78 6 4.5 6Z"
              fill={fill}
            />
            <path
              d="M4.5 10C4.22 10 4 10.22 4 10.5C4 10.78 4.22 11 4.5 11C4.78 11 5 10.78 5 10.5C5 10.22 4.78 10 4.5 10Z"
              fill={fill}
            />
            <path
              d="M6.5 2C6.22 2 6 2.22 6 2.5C6 2.78 6.22 3 6.5 3C6.78 3 7 2.78 7 2.5C7 2.22 6.78 2 6.5 2Z"
              fill={fill}
            />
            <path
              d="M6.5 6C6.22 6 6 6.22 6 6.5C6 6.78 6.22 7 6.5 7C6.78 7 7 6.78 7 6.5C7 6.22 6.78 6 6.5 6Z"
              fill={fill}
            />
            <path
              d="M6.5 10C6.22 10 6 10.22 6 10.5C6 10.78 6.22 11 6.5 11C6.78 11 7 10.78 7 10.5C7 10.22 6.78 10 6.5 10Z"
              fill={fill}
            />
            <path d="M12 2H8V3H12V2Z" fill={fill} />
            <path d="M12 6H8V7H12V6Z" fill={fill} />
            <path d="M12 10H8V11H12V10Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6802">
              <rect
                width="12"
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "REGION":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6820)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.98 0C9.32999 0 7.98999 1.34 7.98999 3C7.98999 5 10.98 9 10.98 9C10.98 9 13.97 5 13.97 3C13.97 1.34 12.64 0 10.98 0ZM10.98 4C10.43 4 9.97999 3.55 9.97999 3C9.97999 2.45 10.43 2 10.98 2C11.53 2 11.98 2.45 11.98 3C11.98 3.55 11.53 4 10.98 4Z"
              fill={fill}
            />
            <path
              d="M7.26999 4.99999L7.11999 4.00999C7.05999 4.00999 6.20999 4.14999 5.22999 4.61999L5.65999 5.51999C6.51999 5.10999 7.25999 4.98999 7.26999 4.98999M4.92999 5.94999L4.36999 5.11999C3.85999 5.46999 3.34999 5.91999 2.93999 6.49999L3.75999 7.06999C4.05999 6.63999 4.44999 6.25999 4.92999 5.93999M3.35999 7.77999L2.43999 7.37999C2.19999 7.92999 2.03999 8.56999 1.98999 9.31999L2.98999 9.37999C3.01999 8.79999 3.14999 8.25999 3.35999 7.77999ZM3.00999 10.24L2.00999 10.32C2.01999 10.48 2.03999 10.64 2.05999 10.8C2.05999 10.86 2.15999 11.47 2.45999 12.26L3.38999 11.9C3.11999 11.21 3.04999 10.67 3.04999 10.67C3.02999 10.52 3.01999 10.38 2.99999 10.24M3.75999 12.68L2.87999 13.16C3.17999 13.7 3.57999 14.25 4.10999 14.72L4.76999 13.97C4.37999 13.62 4.03999 13.19 3.74999 12.67M5.41999 14.44L4.91999 15.31C5.44999 15.61 6.06999 15.84 6.79999 15.94L6.93999 14.95C6.37999 14.87 5.86999 14.7 5.41999 14.44ZM7.78999 15L7.80999 16C8.11999 16 8.44999 15.97 8.79999 15.92C8.84999 15.92 9.22999 15.85 9.77999 15.68L9.46999 14.73C8.98999 14.88 8.65999 14.93 8.65999 14.93C8.35999 14.97 8.06999 15 7.78999 15ZM10.27 14.4L10.7 15.3C11.24 15.04 11.83 14.68 12.35 14.19L11.66 13.46C11.27 13.82 10.8 14.14 10.26 14.39M12.2 12.86L13.01 13.44C13.37 12.94 13.66 12.34 13.83 11.63L12.86 11.4C12.73 11.94 12.51 12.43 12.2 12.85M12.98 10.59L13.98 10.65C14.01 10.18 13.99 9.67999 13.91 9.12999L12.92 9.26999C12.98 9.72999 13 10.18 12.98 10.59Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6820">
              <rect
                width="11.99"
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ROOM":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6814)">
            <path
              d="M1.75 4.5L8.09 8L14.25 4.5"
              stroke={fill}
              strokeMiterlimit="10"
            />
            <path d="M8 8V14.55" stroke={fill} strokeMiterlimit="10" />
            <path
              d="M8 2.12L14 5.12V10.88L8 13.88L2 10.88V5.12L8 2.12ZM8 1L1 4.5V11.5L8 15L15 11.5V4.5L8 1Z"
              fill={fill}
            />
            <path d="M8.12 2L14 4.84L8.12 8L2 4.67L8.12 2Z" fill={fill} />
            <path
              d="M3.48999 12V8L6.00999 9.5V13.5"
              stroke={fill}
              strokeMiterlimit="10"
            />
            <path
              d="M10 9.00999L12.51 7.50999V10.51L10 11.75V9.00999Z"
              stroke={fill}
              strokeMiterlimit="10"
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6814">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ROUTER":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6806)">
            <path
              d="M5.44 2.68C5.29 2.53 5.15 2.39 5 2.25C6.59 0.62 9.33 0.56 11 2.25C10.85 2.39 10.71 2.54 10.56 2.68C9.85 2 9 1.63 8.01 1.63C7.02 1.63 6.16 1.99 5.45 2.68H5.44Z"
              fill={fill}
            />
            <path
              d="M6.27002 3.51C6.12002 3.36 5.98002 3.22 5.83002 3.08C6.91002 1.93 8.96002 1.82 10.17 3.08C10.03 3.22 9.88002 3.36 9.73002 3.51C9.25002 3.05 8.67002 2.8 8.00002 2.8C7.33002 2.8 6.75002 3.04 6.27002 3.51Z"
              fill={fill}
            />
            <path
              d="M9.38 3.86C9.23 4.01 9.09 4.15 8.94 4.29C8.31 3.78 7.68 3.78 7.05 4.29C6.91 4.15 6.76 4.01 6.62 3.86C7.3 3.14 8.57 3.05 9.38 3.86Z"
              fill={fill}
            />
            <path
              d="M8.00002 5.54C7.70002 5.54 7.46002 5.3 7.46002 5C7.46002 4.7 7.70002 4.46 8.00002 4.46C8.30002 4.46 8.54002 4.71 8.54002 5.01C8.54002 5.31 8.29002 5.54 8.00002 5.54Z"
              fill={fill}
            />
            <path
              d="M14 8.04V12.04H2V8.04H14ZM15 7.04H1V13.04H15V7.04Z"
              fill={fill}
            />
            <path
              d="M4.5 9.04C3.95 9.04 3.5 9.49 3.5 10.04C3.5 10.59 3.95 11.04 4.5 11.04C5.05 11.04 5.5 10.59 5.5 10.04C5.5 9.49 5.05 9.04 4.5 9.04Z"
              fill={fill}
            />
            <path d="M9 9.04H8V11.04H9V9.04Z" fill={fill} />
            <path d="M11 9.04H10V11.04H11V9.04Z" fill={fill} />
            <path d="M13 9.04H12V11.04H13V9.04Z" fill={fill} />
            <path
              d="M3 12.41H2V15.04H3V12.41ZM2 14.04V15.04H14V14.04H2ZM13 15.04H14V12.04H13V15.04Z"
              fill={fill}
            />
            <path
              d="M2.01001 1.45L1.14001 1.95L4.57001 7.88L5.43001 7.38L2.01001 1.45Z"
              fill={fill}
            />
            <path
              d="M13.99 1.45L10.57 7.38L11.43 7.88L14.86 1.95L13.99 1.45Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6806">
              <rect
                width="14"
                height="14.04"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "SERVER":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6804)">
            <path d="M14 2V4H2V2H14ZM15 1H1V5H15V1Z" fill={fill} />
            <path
              d="M3.5 2.5C3.22 2.5 3 2.72 3 3C3 3.28 3.22 3.5 3.5 3.5C3.78 3.5 4 3.28 4 3C4 2.72 3.78 2.5 3.5 2.5Z"
              fill={fill}
            />
            <path
              d="M5.5 2.5C5.22 2.5 5 2.72 5 3C5 3.28 5.22 3.5 5.5 3.5C5.78 3.5 6 3.28 6 3C6 2.72 5.78 2.5 5.5 2.5Z"
              fill={fill}
            />
            <path
              d="M7.5 2.5C7.22 2.5 7 2.72 7 3C7 3.28 7.22 3.5 7.5 3.5C7.78 3.5 8 3.28 8 3C8 2.72 7.78 2.5 7.5 2.5Z"
              fill={fill}
            />
            <path d="M13 2.5H9V3.5H13V2.5Z" fill={fill} />
            <path d="M14 7V9H2V7H14ZM15 6H1V10H15V6Z" fill={fill} />
            <path
              d="M3.5 7.5C3.22 7.5 3 7.72 3 8C3 8.28 3.22 8.5 3.5 8.5C3.78 8.5 4 8.28 4 8C4 7.72 3.78 7.5 3.5 7.5Z"
              fill={fill}
            />
            <path
              d="M5.5 7.5C5.22 7.5 5 7.72 5 8C5 8.28 5.22 8.5 5.5 8.5C5.78 8.5 6 8.28 6 8C6 7.72 5.78 7.5 5.5 7.5Z"
              fill={fill}
            />
            <path
              d="M7.5 7.5C7.22 7.5 7 7.72 7 8C7 8.28 7.22 8.5 7.5 8.5C7.78 8.5 8 8.28 8 8C8 7.72 7.78 7.5 7.5 7.5Z"
              fill={fill}
            />
            <path d="M13 7.5H9V8.5H13V7.5Z" fill={fill} />
            <path d="M14 12V14H2V12H14ZM15 11H1V15H15V11Z" fill={fill} />
            <path
              d="M3.5 12.5C3.22 12.5 3 12.72 3 13C3 13.28 3.22 13.5 3.5 13.5C3.78 13.5 4 13.28 4 13C4 12.72 3.78 12.5 3.5 12.5Z"
              fill={fill}
            />
            <path
              d="M5.5 12.5C5.22 12.5 5 12.72 5 13C5 13.28 5.22 13.5 5.5 13.5C5.78 13.5 6 13.28 6 13C6 12.72 5.78 12.5 5.5 12.5Z"
              fill={fill}
            />
            <path
              d="M7.5 12.5C7.22 12.5 7 12.72 7 13C7 13.28 7.22 13.5 7.5 13.5C7.78 13.5 8 13.28 8 13C8 12.72 7.78 12.5 7.5 12.5Z"
              fill={fill}
            />
            <path d="M13 12.5H9V13.5H13V12.5Z" fill={fill} />
            <path
              d="M2.5 6.5L2.5 4.5L2.5 6.5Z"
              stroke={fill}
              strokeMiterlimit="10"
            />
            <path d="M5.5 6.5V4.5V6.5Z" stroke={fill} strokeMiterlimit="10" />
            <path d="M2.5 9.5V11.5V9.5Z" stroke={fill} strokeMiterlimit="10" />
            <path d="M5.5 9.5V11.5V9.5Z" stroke={fill} strokeMiterlimit="10" />
          </g>
          <defs>
            <clipPath id="clip0_924_6804">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "SHELF":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6822)">
            <path d="M13 3V13H3V3H13ZM14 2H2V14H14V2Z" fill={fill} />
            <path d="M13 6H3V7H13V6Z" fill={fill} />
            <path d="M13 9H3V10H13V9Z" fill={fill} />
            <path d="M3 0H2V2H3V0Z" fill={fill} />
            <path d="M14 0H13V2H14V0Z" fill={fill} />
            <path d="M3 14H2V16H3V14Z" fill={fill} />
            <path d="M14 14H13V16H14V14Z" fill={fill} />
            <path
              d="M4.5 4C4.22 4 4 4.22 4 4.5C4 4.78 4.22 5 4.5 5C4.78 5 5 4.78 5 4.5C5 4.22 4.78 4 4.5 4Z"
              fill={fill}
            />
            <path
              d="M6.5 4C6.22 4 6 4.22 6 4.5C6 4.78 6.22 5 6.5 5C6.78 5 7 4.78 7 4.5C7 4.22 6.78 4 6.5 4Z"
              fill={fill}
            />
            <path
              d="M4.5 11C4.22 11 4 11.22 4 11.5C4 11.78 4.22 12 4.5 12C4.78 12 5 11.78 5 11.5C5 11.22 4.78 11 4.5 11Z"
              fill={fill}
            />
            <path
              d="M6.5 11C6.22 11 6 11.22 6 11.5C6 11.78 6.22 12 6.5 12C6.78 12 7 11.78 7 11.5C7 11.22 6.78 11 6.5 11Z"
              fill={fill}
            />
            <path d="M12 4H8V5H12V4Z" fill={fill} />
            <path d="M12 11H8V12H12V11Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6822">
              <rect
                width={width}
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "SWITCH":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6823)">
            <path d="M16 2H0V3H16V2Z" fill="white" />
            <path d="M16 2H0V3H16V2Z" fill={fill} />
            <path d="M16 13H0V14H16V13Z" fill="white" />
            <path d="M16 13H0V14H16V13Z" fill={fill} />
            <path d="M15 5V11H1V5H15ZM16 4H0V12H16V4Z" fill={fill} />
            <path d="M3 6H2V10H3V6Z" fill={fill} />
            <path d="M5 6H4V10H5V6Z" fill={fill} />
            <path d="M10 6H9V7H10V6Z" fill={fill} />
            <path d="M12 6H11V7H12V6Z" fill={fill} />
            <path d="M14 6H13V7H14V6Z" fill={fill} />
            <path d="M10 8H9V9H10V8Z" fill={fill} />
            <path d="M12 8H11V9H12V8Z" fill={fill} />
            <path d="M14 8H13V9H14V8Z" fill={fill} />
            <path d="M8 6H7V7H8V6Z" fill={fill} />
            <path d="M8 8H7V9H8V8Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_924_6823">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );

      break;
    case "ZONE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_924_6821)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 11C4.14 11 1 11.89 1 13C1 14.11 4.14 15 8 15C11.86 15 15 14.1 15 13C15 11.9 11.86 11 8 11ZM7.99 14C5.24 14 3 13.55 3 13C3 12.45 5.24 12 7.99 12C10.74 12 13 12.45 13 13C13 13.55 10.76 14 7.99 14Z"
              fill={fill}
            />
            <path
              d="M8 2C9.65 2 11 3.35 11 5C11 5.36 10.93 5.72 10.79 6.07L10.75 6.17L10.36 6.87L8 10.99L5.64 6.87L5.25 6.17L5.21 6.06C5.07 5.71 5 5.35 5 4.99C5 3.34 6.35 1.99 8 1.99M8 1C5.79 1 4 2.79 4 5C4 5.51 4.1 5.99 4.28 6.44C4.28 6.48 4.3 6.52 4.32 6.55L4.35 6.61L4.77 7.36L8 13L11.23 7.36L11.65 6.61L11.68 6.55C11.68 6.55 11.72 6.48 11.72 6.44C11.9 5.99 12 5.51 12 5C12 2.79 10.21 1 8 1Z"
              fill={fill}
            />
            <path
              d="M8 4C8.55 4 9 4.45 9 5C9 5.55 8.55 6 8 6C7.45 6 7 5.55 7 5C7 4.45 7.45 4 8 4ZM8 3C6.9 3 6 3.9 6 5C6 6.1 6.9 7 8 7C9.1 7 10 6.1 10 5C10 3.9 9.1 3 8 3Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_924_6821">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "DELETE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.00049 14.0002H7.00149V6.00021H6.00049V14.0002Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.00049 14.0002H10.0005V6.00021H9.00049V14.0002Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 0V2H2V5H3V16H13V5H14V2H10.001V0H6ZM7.001 2H9.001V1H7.001V2ZM3 3.001H6H10.001H13V4.001H3V3.001ZM4 15H12V5H4V15Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "ARROW_RIGHT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.839 3L11 7.999L5.839 13L5 12.188L9.324 7.999L5 3.812L5.839 3Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "ARROW_LEFT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill={fill}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.161 3L5 7.999L10.161 13L11 12.188L6.676 7.999L11 3.812L10.161 3Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "CHECK":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 7L0 8L6.00134 14L16 4L15 3L11 7L6 12L3.5 9.5L1 7Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "ARROW_DOWN_SMALL":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32060)">
            <path d="M5.5 6H11.51L8.5 10L5.5 6Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_1594_32060">
              <rect
                width="6.01"
                height="4"
                fill="white"
                transform="translate(5.5 6)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "FIT_HEIGHT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32063)">
            <path
              d="M9.98002 12.45L8.02002 14.52L6.02002 12.45"
              stroke={fill}
              strokeMiterlimit="10"
            />
            <path d="M8 9.45001V14.52" stroke={fill} strokeMiterlimit="10" />
            <path
              d="M6.02002 3.57999L7.98002 1.51999L9.98002 3.57999"
              stroke={fill}
              strokeMiterlimit="10"
            />
            <path d="M8 6.57999V1.51999" stroke={fill} strokeMiterlimit="10" />
            <path d="M14 0H2V1H14V0Z" fill="white" />
            <path d="M14 0H2V1H14V0Z" fill={fill} />
            <path d="M14 15H2V16H14V15Z" fill="white" />
            <path d="M14 15H2V16H14V15Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_1594_32063">
              <rect
                width="12"
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "FIT_WIDTH":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32064)">
            <path d="M1 2H0V14H1V2Z" fill={fill} />
            <path d="M16 2H15V14H16V2Z" fill={fill} />
            <path
              d="M3.52997 9.97999L1.46997 8.01999L3.52997 6.01999"
              stroke={fill}
              strokeMiterlimit="10"
            />
            <path d="M6.52997 8H1.46997" stroke={fill} strokeMiterlimit="10" />
            <path
              d="M12.47 9.97999L14.53 8.01999L12.47 6.01999"
              stroke={fill}
              strokeMiterlimit="10"
            />
            <path d="M9.46997 8H14.53" stroke={fill} strokeMiterlimit="10" />
          </g>
          <defs>
            <clipPath id="clip0_1594_32064">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "ROTATE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32065)">
            <path
              d="M10.7917 11.4331C7.71262 11.6906 4.62111 10.3366 2.74751 7.70691L1.63705 8.34804C3.68955 11.3086 6.99969 12.8766 10.4232 12.7332C10.5204 12.7291 10.6944 12.74 10.7917 12.7332C11.7724 12.6639 13.0498 12.3626 14 12C14.7171 11.6487 14.6807 11.6666 15.3614 11.3331L16 11L11.2121 9.88738L10.7917 11.4331ZM1.9048 4.10336L1.42902 4.40766L7 5.5L6.2942 4.0467C9.37751 3.79652 12.4647 5.14315 14.3309 7.77711L15.4414 7.13599C13.1857 3.87207 9.29916 2.28138 5.52966 2.83763C4.28783 3.02088 3.05871 3.43714 1.9048 4.10336Z"
              fill={fill}
            />
            <path
              d="M1.42902 4.40766L1.9048 4.10336C3.05871 3.43714 4.28783 3.02088 5.52966 2.83763L5 2L1.42902 4.40766Z"
              fill={fill}
            />
            <path
              d="M14 12C13.0498 12.3626 11.7724 12.6639 10.7917 12.7332C10.6944 12.74 10.5204 12.7291 10.4232 12.7332L10 14L14 12Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_1594_32065">
              <rect
                width={width}
                height={height}
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "GLOBE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32062)">
            <path
              d="M8 2C11.31 2 14 4.69 14 8C14 11.31 11.31 14 8 14C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2ZM8 1C4.13 1 1 4.13 1 8C1 11.87 4.13 15 8 15C11.87 15 15 11.87 15 8C15 4.13 11.87 1 8 1Z"
              fill={fill}
            />
            <path
              d="M8 2.08C8.68 2.69 10 5.38 10 8C10 10.62 8.68 13.32 8 13.92C7.32 13.31 6 10.62 6 8C6 5.38 7.32 2.68 8 2.08ZM8 1C6.9 1 5 4.69 5 8C5 11.31 6.9 15 8 15C9.1 15 11 11.31 11 8C11 4.69 9.1 1 8 1Z"
              fill={fill}
            />
            <path d="M2 5.5H13.99" stroke={fill} strokeMiterlimit="10" />
            <path d="M2 10.5H13.99" stroke={fill} strokeMiterlimit="10" />
          </g>
          <defs>
            <clipPath id="clip0_1594_32062">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "REGIONAL_PREFERENCES":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32248)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.98 0C9.32999 0 7.98999 1.34 7.98999 3C7.98999 5 10.98 9 10.98 9C10.98 9 13.97 5 13.97 3C13.97 1.34 12.64 0 10.98 0ZM10.98 4C10.43 4 9.97999 3.55 9.97999 3C9.97999 2.45 10.43 2 10.98 2C11.53 2 11.98 2.45 11.98 3C11.98 3.55 11.53 4 10.98 4Z"
              fill={fill}
            />
            <path
              d="M7.26999 4.99999L7.11999 4.00999C7.05999 4.00999 6.20999 4.14999 5.22999 4.61999L5.65999 5.51999C6.51999 5.10999 7.25999 4.98999 7.26999 4.98999M4.92999 5.94999L4.36999 5.11999C3.85999 5.46999 3.34999 5.91999 2.93999 6.49999L3.75999 7.06999C4.05999 6.63999 4.44999 6.25999 4.92999 5.93999M3.35999 7.77999L2.43999 7.37999C2.19999 7.92999 2.03999 8.56999 1.98999 9.31999L2.98999 9.37999C3.01999 8.79999 3.14999 8.25999 3.35999 7.77999ZM3.00999 10.24L2.00999 10.32C2.01999 10.48 2.03999 10.64 2.05999 10.8C2.05999 10.86 2.15999 11.47 2.45999 12.26L3.38999 11.9C3.11999 11.21 3.04999 10.67 3.04999 10.67C3.02999 10.52 3.01999 10.38 2.99999 10.24M3.75999 12.68L2.87999 13.16C3.17999 13.7 3.57999 14.25 4.10999 14.72L4.76999 13.97C4.37999 13.62 4.03999 13.19 3.74999 12.67M5.41999 14.44L4.91999 15.31C5.44999 15.61 6.06999 15.84 6.79999 15.94L6.93999 14.95C6.37999 14.87 5.86999 14.7 5.41999 14.44ZM7.78999 15L7.80999 16C8.11999 16 8.44999 15.97 8.79999 15.92C8.84999 15.92 9.22999 15.85 9.77999 15.68L9.46999 14.73C8.98999 14.88 8.65999 14.93 8.65999 14.93C8.35999 14.97 8.06999 15 7.78999 15ZM10.27 14.4L10.7 15.3C11.24 15.04 11.83 14.68 12.35 14.19L11.66 13.46C11.27 13.82 10.8 14.14 10.26 14.39M12.2 12.86L13.01 13.44C13.37 12.94 13.66 12.34 13.83 11.63L12.86 11.4C12.73 11.94 12.51 12.43 12.2 12.85M12.98 10.59L13.98 10.65C14.01 10.18 13.99 9.67999 13.91 9.12999L12.92 9.26999C12.98 9.72999 13 10.18 12.98 10.59Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_1594_32248">
              <rect
                width="11.99"
                height={height}
                fill="white"
                transform="translate(2)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "TIME_ZONE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 8.00031L10.002 7.99631L10 7.99531V7.00031H6V8.00031H8.889L6.68 12.5633L7.58 12.9983L10 8.00031Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 1V2.001H6V1H4V2.001H1V15H15V2.001H12V1H10ZM2 4H14V3H2V4ZM2 14.001H14V5H2V14.001Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "THEME":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.263 1L6.08 1.742L5.892 2.501L5.828 2.76L5.599 2.622L4.929 2.217L4.275 1.821L3.735 2.362L2.361 3.735L1.822 4.273L2.214 4.927L2.611 5.588L2.755 5.828L2.506 5.89L1.739 6.081L1 6.266V7.027V8.973V9.738L1.742 9.92L2.523 10.113L2.757 10.17L2.214 11.075L1.822 11.727L2.361 12.265L3.047 12.953L3.735 13.64L4.273 14.179L4.926 13.785L5.622 13.368L5.827 13.244L6.081 14.261L6.266 15H7.027H8.973H9.732L9.918 14.264L10.119 13.47L10.176 13.244L10.372 13.362L11.072 13.785L11.725 14.18L12.265 13.64L12.952 12.953L13.639 12.265L14.178 11.725L13.784 11.072L13.362 10.373L13.243 10.176L13.47 10.12L14.263 9.919L15 9.733V6.264L14.259 6.08L13.465 5.884L13.243 5.829L13.785 4.927L14.178 4.273L13.639 3.735L12.952 3.048L12.265 2.362L11.725 1.822L11.072 2.216L10.407 2.616L10.177 2.755L10.11 2.49L9.918 1.735L9.731 1H6.263ZM6.652 3.495L6.839 2.735L7.027 1.976H8.973L9.165 2.731L9.356 3.486L10.244 3.853L10.91 3.452L11.576 3.051L12.262 3.738L12.949 4.424L12.13 5.79L12.284 6.213L12.437 6.635L13.231 6.831L14.025 7.027V8.973L13.231 9.174L12.437 9.374L12.271 9.775L12.105 10.176L12.527 10.877L12.949 11.576L12.262 12.263L11.576 12.95L10.876 12.527L10.176 12.105L9.775 12.272L9.373 12.438L9.174 13.232L8.973 14.025H7.027L6.635 12.455L5.815 12.113L5.12 12.532L4.424 12.95L3.738 12.263L3.05 11.576L3.87 10.211L3.878 10.198L3.887 10.185L3.792 9.963L3.699 9.742L3.618 9.549L3.537 9.357L2.756 9.165L1.975 8.973V7.027L2.743 6.836L3.511 6.644L3.537 6.635L3.699 6.204L3.861 5.773L3.844 5.747L3.447 5.086L3.05 4.424L4.424 3.051L5.094 3.456L5.764 3.862L6.652 3.495Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99976 5.0004C6.34576 5.0004 4.99976 6.3464 4.99976 8.0004C4.99976 9.6544 6.34576 11.0004 7.99976 11.0004C9.65376 11.0004 10.9998 9.6544 10.9998 8.0004C10.9998 6.3464 9.65376 5.0004 7.99976 5.0004ZM7.99976 6.0004C9.10476 6.0004 9.99976 6.8954 9.99976 8.0004C9.99976 9.1054 9.10476 10.0004 7.99976 10.0004C6.89476 10.0004 5.99976 9.1054 5.99976 8.0004C5.99976 6.8954 6.89476 6.0004 7.99976 6.0004Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "MORE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2.5" cy="8.5" r="1.5" fill={fill} />
          <circle cx="8" cy="8.5" r="1.5" fill={fill} />
          <circle cx="13.5" cy="8.5" r="1.5" fill={fill} />
        </svg>
      );
      break;
    case "HUMIDITY":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32058)">
            <path
              d="M9 1.68L12.63 6.57L13.31 7.47L13.59 8C13.86 8.62 14 9.3 14 10C14 12.86 11.36 15 9 15C6.64 15 4 12.86 4 10C4 9.3 4.14 8.62 4.41 8H4.42L4.69 7.47L5.37 6.57L9 1.68ZM9 0L4.57 5.97L3.84 6.94C3.84 6.94 3.82 6.98 3.81 7H3.8C3.29 7.88 3 8.9 3 10C3 13.31 6 16 9 16C12 16 15 13.31 15 10C15 8.9 14.71 7.88 14.2 7H14.19C14.19 7 14.17 6.96 14.16 6.94L13.43 5.97L9 0Z"
              fill={fill}
            />
            <path
              d="M4.5 12.5C4.57 12.48 4.52 12.54 6.01 11.52C6.79 10.99 7.43 11.28 7.87 11.67C8.62 12.35 9.8 12.46 10.54 11.77C10.75 11.57 10.93 11.32 11.03 11C11.51 9.5 14.42 12 14.42 12"
              stroke={fill}
              strokeMiterlimit="10"
            />
            <path
              d="M8 5C7.45 5 7 5.45 7 6C7 6.55 7.45 7 8 7C8.55 7 9 6.55 9 6C9 5.45 8.55 5 8 5Z"
              fill={fill}
            />
            <path
              d="M10 8C9.45 8 9 8.45 9 9C9 9.55 9.45 10 10 10C10.55 10 11 9.55 11 9C11 8.45 10.55 8 10 8Z"
              fill={fill}
            />
            <path d="M10.5 5.5L7.5 9.5" stroke={fill} strokeMiterlimit="10" />
          </g>
          <defs>
            <clipPath id="clip0_1594_32058">
              <rect
                width="12"
                height={height}
                fill="white"
                transform="translate(3)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "MINIMIZE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.48191 5.829V2.536H8.48291V7.536H13.4849V6.536L10.1929 6.533L15.0099 1.715L14.3129 1L9.48191 5.829Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.524 8.46509V9.46409L5.816 9.46809L1 14.2861L1.697 15.0021L6.527 10.1721V13.4651H7.526V8.46509H2.524Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "MAXIMIZE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0072 1V2L13.2992 2.004L8.48218 6.821L9.17918 7.537L14.0092 2.707V6H15.0092V1H10.0072Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.999 13.2949V10.0019H1V15.0019H6.002V14.0029L2.71 13.9989L7.527 9.18091L6.83 8.46591L1.999 13.2949Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "MONEY":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32056)">
            <path
              d="M8.5 2C11.81 2 14.5 4.69 14.5 8C14.5 11.31 11.81 14 8.5 14C5.19 14 2.5 11.31 2.5 8C2.5 4.69 5.19 2 8.5 2ZM8.5 1C4.63 1 1.5 4.13 1.5 8C1.5 11.87 4.63 15 8.5 15C12.37 15 15.5 11.87 15.5 8C15.5 4.13 12.37 1 8.5 1Z"
              fill={fill}
            />
            <path d="M8.5 3V4.82V3Z" stroke={fill} strokeMiterlimit="10" />
            <path
              d="M6.5 9.82C6.5 10.82 7.4 11.64 8.5 11.64C9.6 11.64 10.5 10.83 10.5 9.82C10.5 8.81 9.6 8 8.5 8C7.4 8 6.5 7.19 6.5 6.18C6.5 5.17 7.4 4.36 8.5 4.36C9.6 4.36 10.5 5.17 10.5 6.18"
              stroke={fill}
              strokeMiterlimit="10"
            />
            <path d="M8.5 13V11.18V13Z" stroke={fill} strokeMiterlimit="10" />
          </g>
          <defs>
            <clipPath id="clip0_1594_32056">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1.5 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "PAYMENT_METHOD":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32061)">
            <path
              d="M15.5 3V13H1.5V3H15.5ZM16.5 2H0.5V14H16.5V2Z"
              fill={fill}
            />
            <path d="M15.5 4H1.5V6H15.5V4Z" fill={fill} />
            <path
              d="M13.5 10C13.31 10 13.15 10.06 13 10.15C12.85 10.06 12.69 10 12.5 10C11.95 10 11.5 10.45 11.5 11C11.5 11.55 11.95 12 12.5 12C12.69 12 12.85 11.94 13 11.85C13.15 11.94 13.31 12 13.5 12C14.05 12 14.5 11.55 14.5 11C14.5 10.45 14.05 10 13.5 10Z"
              fill={fill}
            />
            <path d="M5.5 9H2.5V10H5.5V9Z" fill={fill} />
            <path d="M7.5 11H2.5V12H7.5V11Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_1594_32061">
              <rect
                width={width}
                height="12"
                fill="white"
                transform="translate(0.5 2)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "UPGRADE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32059)">
            <path
              d="M8.5 2C11.81 2 14.5 4.69 14.5 8C14.5 11.31 11.81 14 8.5 14C5.19 14 2.5 11.31 2.5 8C2.5 4.69 5.19 2 8.5 2ZM8.5 1C4.63 1 1.5 4.13 1.5 8C1.5 11.87 4.63 15 8.5 15C12.37 15 15.5 11.87 15.5 8C15.5 4.13 12.37 1 8.5 1Z"
              fill={fill}
            />
            <path d="M9.5 12H7.5V13H9.5V12Z" fill={fill} />
            <path d="M9.5 10H7.5V11H9.5V10Z" fill={fill} />
            <path d="M8.5 3L5.5 6H7.5V9H9.5V6H11.5L8.5 3Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_1594_32059">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1.5 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "IMPORT_EXPORT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1594_32057)">
            <path
              d="M1.5 12L5 15L8.5 12L7.88 11.17L5.5 13.31V6H4.5V13.33L2.1 11.27L1.5 12Z"
              fill={fill}
            />
            <path
              d="M8.5 4L12 1L15.5 4L14.88 4.83L12.5 2.69V10H11.5V2.67L9.1 4.73L8.5 4Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_1594_32057">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1.5 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "DRAG_POINT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 1H5V3H7V1ZM5 5H7V7H5V5ZM7 9H5V11H7V9ZM7 13H5V15H7V13ZM9 13H11V15H9V13ZM11 9H9V11H11V9ZM9 5H11V7H9V5ZM11 1H9V3H11V1Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "CLEAR":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.49 1L2.169 1.321L1.322 2.168L1 2.49L1.322 2.811L6.511 8.001L1.323 13.19L1.001 13.511L1.323 13.831L2.17 14.679L2.492 15L2.812 14.679L8.002 9.491L13.188 14.678L13.51 14.999L13.831 14.678L14.678 13.83L14.999 13.509L14.678 13.189L9.491 8.001L14.68 2.812L15.001 2.491L14.68 2.17L13.832 1.322L13.51 1.001L13.19 1.322L8.002 6.511L2.811 1.321L2.49 1Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "UNDO":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.98389 5.0005V6.0005L14 6V10H3.99999V11H15V5L3.98389 5.0005Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.052 1L1 7.986L7.033 15L7.805 14.331L2.846 8.558L2.335 7.989L7.843 1.698L7.052 1Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "REDO":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0161 5.0005V6.0005L2.00001 6V10H12V11H1.00001V5L12.0161 5.0005Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.948 1L15 7.986L8.967 15L8.195 14.331L13.154 8.558L13.665 7.989L8.157 1.698L8.948 1Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "ATTACHMENT":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.195 1L5.496 1.72L13.984 10.019L14 14.001H11L2.421 5.495L4.523 3.395L11.783 10.688L11.114 11.362L5.621 6L4.914 6.708L11.118 12.78L13.193 10.686L4.525 1.98L1 5.495L10.507 14.994L15.001 15V9.599L6.195 1Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "DOWNLOAD":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 15.0004H15V14.0004H1V15.0004Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00095 13.001L11.502 10L10.881 9.16701L8.49995 11.309L8.49995 1.00001H7.50095L7.50095 11.33L5.10595 9.27001L4.50095 10L8.00095 13.001Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "VISIBILITY":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 4C5.791 4 4 5.791 4 8C4 10.209 5.791 12 8 12C10.209 12 12 10.209 12 8C12 5.791 10.209 4 8 4M8 5C9.654 5 11 6.346 11 8C11 9.654 9.654 11 8 11C6.346 11 5 9.654 5 8C5 6.346 6.346 5 8 5"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 3C3.691 3 0.319 7.294 0.177 7.477C-0.059 7.783 -0.059 8.217 0.177 8.523C0.319 8.706 3.691 13 8 13C12.309 13 15.681 8.706 15.823 8.523C16.059 8.217 16.059 7.783 15.823 7.477C15.681 7.294 12.309 3 8 3M8 4C11.534 4 14.464 7.392 14.961 8C14.454 8.62 11.528 12 8 12C4.466 12 1.536 8.608 1.039 8C1.546 7.38 4.472 4 8 4"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 7C7.448 7 7 7.448 7 8C7 8.552 7.448 9 8 9C8.552 9 9 8.552 9 8C9 7.448 8.552 7 8 7"
            fill={fill}
          />
        </svg>
      );
      break;
    case "EASY_TO_USE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2220_2793)">
            <path
              d="M10.9039 6.04547C7.9258 7.12315 7.04523 7.99509 5.95689 10.944C4.86855 7.99509 3.98799 7.12315 1 6.04547C3.97809 4.958 4.85866 4.08606 5.95689 1.13715C7.04523 4.08606 7.9258 4.958 10.9039 6.04547Z"
              fill={fill}
            />
            <path
              d="M15 3.10637C13.7237 3.56683 13.3477 3.93912 12.8728 5.21274C12.4078 3.94892 12.0318 3.57663 10.7456 3.10637C12.0219 2.64591 12.3979 2.27362 12.8728 1C13.3378 2.26382 13.7138 2.63611 15 3.10637Z"
              fill={fill}
            />
            <path
              d="M13.0212 12.9034C11.7449 13.3639 11.3689 13.7362 10.894 15.0098C10.429 13.746 10.053 13.3737 8.76678 12.9034C10.0431 12.443 10.4191 12.0707 10.894 10.7971C11.359 12.0609 11.735 12.4332 13.0212 12.9034Z"
              fill={fill}
            />
          </g>
          <defs>
            <clipPath id="clip0_2220_2793">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "FLEXIBILITY":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 11.4H12.6V9H11.4V11.4H9V12.6H11.4V15H12.6V12.6H15V11.4Z"
            fill={fill}
          />
          <rect x="1.5" y="1.5" width="5" height="5" stroke={fill} />
          <rect x="9.5" y="1.5" width="5" height="5" stroke={fill} />
          <rect x="1.5" y="9.5" width="5" height="5" stroke={fill} />
        </svg>
      );
      break;
    case "MULTIPLE_FORMATS":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2206_4696)">
            <path
              d="M14 2L14 1L1 1L1 2L14 2ZM2.00001 1L1 1L1 10L2.00001 10L2.00001 1ZM1 9L1 10L10 10L10 9L1 9Z"
              fill={fill}
            />
            <path d="M15 1L14 1L14 7L15 7L15 1Z" fill={fill} />
            <path d="M9 9H7V13H9V9Z" fill={fill} />
            <path d="M11 9L11 15L12 15L12 9L11 9Z" fill={fill} />
            <path d="M14 9L14 15L15 15L15 9L14 9Z" fill={fill} />
            <path d="M15 14H11V15H15V14Z" fill={fill} />
            <path d="M15 8H11V9H15V8Z" fill={fill} />
            <path d="M15 13H11V14H15V13Z" fill={fill} />
            <path d="M10 13H5V15H10V14V13Z" fill={fill} />
          </g>
          <defs>
            <clipPath id="clip0_2206_4696">
              <rect
                width="14"
                height="14"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    case "CANCEL":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 4L4 5L11 12L12 11L5 4Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 4L12 5L5 12L4 11L11 4Z"
            fill={fill}
          />
          <circle cx="8" cy="8" r="6.5" stroke={fill} />
        </svg>
      );
      break;
    case "WARNING":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1L1 15H15L8 1Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99976 14H8.99976V12H6.99976V14Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99976 11.0004H8.99976V6.0014H6.99976V11.0004Z"
            fill="currentColor"
          />
        </svg>
      );
      break;
    case "NOTIFICATION":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4479 11.3883C14.3798 11.3883 12.852 11.1006 12.852 6.3939C12.852 3.06966 11.0216 1 7.99622 1C4.97083 1 3.14046 3.06966 3.14046 6.3939C3.14046 11.1885 1.5597 11.3883 1.56726 11.3883C1.41681 11.3883 1.27253 11.4514 1.16615 11.5638C1.05976 11.6762 1 11.8286 1 11.9876C1 12.1465 1.05976 12.299 1.16615 12.4114C1.27253 12.5238 1.41681 12.5869 1.56726 12.5869H5.22042C5.34669 13.2667 5.69255 13.879 6.19888 14.3192C6.7052 14.7593 7.34053 15 7.99622 15C8.65191 15 9.28724 14.7593 9.79356 14.3192C10.2999 13.879 10.6457 13.2667 10.772 12.5869H14.4327C14.5832 12.5869 14.7275 12.5238 14.8339 12.4114C14.9402 12.299 15 12.1465 15 11.9876C15 11.8286 14.9402 11.6762 14.8339 11.5638C14.7275 11.4514 14.5832 11.3883 14.4327 11.3883H14.4479ZM7.99622 13.7855C7.6455 13.7848 7.30363 13.6692 7.01778 13.4545C6.73193 13.2398 6.51619 12.9366 6.40032 12.5869H9.59211C9.47625 12.9366 9.26051 13.2398 8.97466 13.4545C8.68881 13.6692 8.34694 13.7848 7.99622 13.7855ZM3.27661 11.3883C3.82118 10.5172 4.27499 8.99096 4.27499 6.3939C4.27499 3.79684 5.63641 2.19864 7.99622 2.19864C10.356 2.19864 11.7175 3.72492 11.7175 6.3939C11.7175 9.06288 12.1713 10.5172 12.7158 11.3883H3.27661Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "HORIZONTAL_RULE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 8H15" stroke={fill} />
        </svg>
      );
      break;
    case "REFRESH":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_2730_89"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="14"
            height="14"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1 1H14.9998V15H1V1Z"
              fill="currentColor"
            />
          </mask>
          <g mask="url(#mask0_2730_89)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.9998 8L13.9988 8.462C13.7438 11.766 10.8548 14.237 7.54682 13.982C4.23882 13.727 1.76382 10.842 2.01882 7.538C2.26082 4.413 4.86982 2 8.00882 2C9.61282 1.995 11.1508 2.636 12.2758 3.776C12.3658 3.867 12.4398 3.936 12.5078 4H9.99982V5H13.9998V1H13.0158V3.1C13.0118 3.096 13.0068 3.093 13.0028 3.089C12.9968 3.084 12.9928 3.078 12.9878 3.073C10.2628 0.327 5.82582 0.306 3.07582 3.027C0.325824 5.749 0.305825 10.181 3.02982 12.927C4.34582 14.253 6.13882 15 8.00882 15C11.6658 14.986 14.7028 12.178 14.9988 8.538C14.9978 8.539 14.9998 8 14.9998 8H13.9998Z"
              fill={fill}
            />
          </g>
        </svg>
      );
      break;
    case "STORAGE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13 2V14H3V2H13ZM14 1H2V15H14V1Z" fill={fill} />
          <path d="M5 4H4V5H5V4Z" fill={fill} />
          <path d="M5 6H4V7H5V6Z" fill={fill} />
          <path d="M5 8H4V9H5V8Z" fill={fill} />
          <path d="M5 10H4V11H5V10Z" fill={fill} />
          <path d="M12 4H6V5H12V4Z" fill={fill} />
          <path d="M12 6H6V7H12V6Z" fill={fill} />
          <path d="M12 8H6V9H12V8Z" fill={fill} />
          <path d="M12 10H6V11H12V10Z" fill={fill} />
        </svg>
      );
      break;
    case "GALLERY":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.49378 10V3H14.9578V8L11.8312 5L8.1836 8.5L6.62033 7L3.49378 10ZM16 9V2H2.45166V12H10.7891V9H16Z"
            fill={fill}
          />
          <rect x="10.7891" y="9" width="5.21092" height="3" fill={fill} />
          <path d="M2.93548 4.5H1V13.5H14.5484V11.5" stroke={fill} />
        </svg>
      );
      break;
    case "INFO":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1L1 8.00108L8 15.0011L15 8.00108L8 1Z"
            fill="currentColor"
            stroke={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99976 12.0004H8.99976V7.0004H6.99976V12.0004Z"
            fill={fill}
          />
          <rect x="7" y="4" width="2" height="2" fill={fill} />
        </svg>
      );
      break;
    case "CHECKBOX":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 15H15V1H1V15Z"
            stroke={fill}
          />
        </svg>
      );
      break;
    case "CHECKBOX_DONE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 15H15V1H1V15Z"
            stroke={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 4.5L12.5 5.5L6.5 11.5L3.5 8.5L4.5 7.5L6.5 9.5L11.5 4.5Z"
            fill={fill}
          />
        </svg>
      );
      break;
    case "IMAGE":
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.07692 11V4H13.9231V9L10.6923 6L6.92308 9.5L5.30769 8L2.07692 11ZM15 10V3H1V13H9.61539V10H15Z"
            fill={fill}
          />
          <rect x="9.61536" y="10" width="5.38462" height="3" fill={fill} />
        </svg>
      );
      break;
    default:
      icon = (
        <svg
          width={width}
          height={height}
          style={customStyle}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_924_6794"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="11"
            height="11"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.99951 4.98384H14.9995V15H4.99951V4.98384Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_924_6794)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.0065 10.4148C10.8835 10.4148 11.5565 9.31196 11.5565 7.93369C11.5565 6.7247 10.9895 6.00753 10.0065 6.00753C9.02351 6.00753 8.45651 6.7247 8.45651 7.93369C8.45651 9.31196 9.12951 10.4148 10.0065 10.4148ZM9.99951 11.4244C11.4135 11.4244 12.4995 9.81178 12.4995 7.79847C12.4995 6.03157 11.5855 4.98384 9.99951 4.98384C8.41451 4.98384 7.49951 6.03157 7.49951 7.79847C7.49951 9.81178 8.58551 11.4244 9.99951 11.4244ZM14.0065 13.9987H5.99551V13.3215C5.99551 12.7135 6.39951 11.9894 7.00651 11.9894H7.80751C8.43051 12.4862 9.19651 12.7726 9.99251 12.8047C10.7935 12.7756 11.5635 12.4922 12.1925 11.9964H13.0065C13.6145 11.9964 14.0065 12.7206 14.0065 13.3296V13.9987ZM11.9145 10.9827C11.4135 11.5076 10.7245 11.9733 9.99951 11.9843C9.27551 11.9733 8.58651 11.5076 8.08551 10.9827H6.66451C5.74451 10.9877 5.00051 11.9623 4.99951 12.8838L5.00001 15H15L14.9995 12.8838C14.9995 11.9623 14.2555 10.9877 13.3355 10.9827H11.9145Z"
              fill={fill}
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 9.99177L1.995 9.3387C1.995 8.7307 2.399 8.00651 3.007 8.00651H3.807C4.431 8.50333 5.196 8.7888 5.993 8.82185C6.332 8.80883 6.663 8.74172 6.983 8.64055V7.71303C6.674 7.88231 6.343 7.99649 6 8.0015C5.276 7.99048 4.501 7.5107 4 6.98684L2.664 6.99986C1.744 7.00386 1 7.97947 1 8.90098V11H5V10L2 9.99177Z"
            fill={fill}
          />
          <mask
            id="mask1_924_6794"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="3"
            y="1"
            width="6"
            height="7"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.50049 1H8.50049V7.44148H3.50049V1Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_924_6794)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.98349 5.87591C6.71849 6.21847 6.38349 6.43182 6.00649 6.43182C5.12949 6.43182 4.45649 5.32801 4.45649 3.94975C4.45649 2.74176 5.02349 2.02458 6.00649 2.02458C6.98949 2.02458 7.55649 2.74176 7.55649 3.94975C7.55649 4.31635 7.50649 4.66092 7.41949 4.97343H8.36249C8.44549 4.60783 8.50049 4.2232 8.50049 3.81452C8.50049 2.04862 7.58549 0.999901 6.00049 0.999901C4.41449 0.999901 3.50049 2.04862 3.50049 3.81452C3.50049 5.82883 4.58549 7.44148 5.99949 7.44148C6.35249 7.44148 6.68349 7.34032 6.98349 7.15902V6.9066V5.87591Z"
              fill={fill}
            />
          </g>
        </svg>
      );

      break;
  }

  return icon;
};
