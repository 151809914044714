import React, { useState } from "react";
import { DeleteButton } from "../../styles/assets/CrudForm.styles";
import { useTranslation } from "react-i18next";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { messageError, messageSuccess } from "../../../util/notification";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { setTypeId } from "../../../store/slices/typeSlice";
import { useMediaQuery, useTheme } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";

const TypeDelete = ({ typeId, deleteType }) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [open, setOpen] = useState(false);

  const handleDeleteConfirm = () => {
    setOpen(true);
  };

  const deleteConfirm = async () => {
    try {
      dispatch(setTypeId(null));
      await deleteType({
        typeId,
        organizationId: user?.organizations?.find((o) => o.default).id,
      }).unwrap();

      if (!desktopMatches) {
        navigate(`/library?direction=${transitionDirections.TOP_TO_BOTTOM}`);
      }

      messageSuccess(getTranslation("successfulDeleteType", t, i18n));
    } catch (error) {
      const { error: errorPlaceholder } = error.data;
      messageError(
        `${getTranslation("failedDeleteType", t, i18n)} ${
          errorPlaceholder === "RELATED_RESOURCE_RECORD"
            ? getTranslation("RELATED_RESOURCE_RECORD", t, i18n)
            : ""
        }`
      );
    }
  };

  // Other variables
  const iconSize = globalFontSize;

  return (
    <>
      <ConfirmAlert
        isOpen={open}
        setIsOpen={setOpen}
        alert={{
          content: getTranslation("TYPE_DELETE_MESSAGE", t, i18n),
          confirmTitle: getTranslation("DELETE_TYPE", t, i18n),
          closeTitle: getTranslation("CANCEL", t, i18n),
          showConfirm: true,
        }}
        label="delete"
        handleConfirm={deleteConfirm}
      />
      <div>
        <DeleteButton
          id="delete-button"
          data-testid="delete-button"
          onClick={handleDeleteConfirm}
          startIcon={getSvgIcon(
            "DELETE",
            iconSize,
            iconSize,
            theme.palette.error.main
          )}
          variant="text"
          color="error"
        >
          {getTranslation("DELETE_TYPE", t, i18n)}
        </DeleteButton>
      </div>
    </>
  );
};

export default TypeDelete;
