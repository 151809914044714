import React, { useCallback, useRef, useState } from "react";
import {
  Backdrop,
  Grid,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Item,
  ItemContainer,
  ItemName,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import {
  TypeItemContainer,
  TypeNameGridContainer,
} from "../../styles/types/type-list/TypeItem.styles";
import { useGetTypeFavoritesQuery } from "../../../store/slices/api/typesApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";
import { LongPressEventType, useLongPress } from "use-long-press";
import LongPressTypesItemMenu from "./LongPressTypesItemMenu";

const getIconCreationType = (organizationId, globalFontSize, theme) => {
  const iconSize = globalFontSize * 1.25;

  return organizationId
    ? getSvgIcon("PROFILE", iconSize, iconSize, theme.palette.primary.main)
    : getSvgIcon(
        "MODEL_LIBRARY",
        iconSize,
        iconSize,
        theme.palette.primary.main
      );
};
const FavoriteTypeItem = ({
  type,
  lastIndex,
  currentTypeId,
  currentTypeHandler,
}) => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressed, setLongPressed] = useState(false);
  const [rightClicked, setRightClicked] = useState(false);

  // Refs
  const elementRef = useRef();

  // Callbacks
  const callback = useCallback(() => {
    handleOpen();
  }, []);

  // Longpress
  const bind = useLongPress(
    (mobileMatches || tabletMatches) && type?.organizationId ? callback : null,
    {
      filterEvents: (event) => true, // All events can potentially trigger long press
      threshold: 1000,
      captureEvent: true,
      cancelOnMovement: false,
      cancelOutsideElement: false,
      detect: LongPressEventType.Pointer,
    }
  );

  // Other variables
  const userOrganizationId = user?.organizations?.find((o) => o.default)?.id;
  const handlers = bind("longpress context");
  const open = longPressed || rightClicked;
  const { id, name, organizationId } = type;

  const creationType = organizationId
    ? getTranslation("USER_CREATED_TYPE", t, i18n)
    : getTranslation("SYSTEM_CREATED_TYPE", t, i18n);

  const iconSize = globalFontSize * 1.2;

  // Queries
  const {
    data: dataFavorites,
    isLoading,
    isError,
  } = useGetTypeFavoritesQuery({
    organizationId: userOrganizationId,
  });

  // Other variables
  const isFavorite = dataFavorites?.data?.some((f) => f.type.id === id);

  // Handlers
  const handleClick = () => {
    if (desktopMatches) {
      currentTypeHandler(id);
    } else {
      navigate(
        `/library/${id}/details?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    }
  };

  const handleOpen = () => {
    setAnchorEl(elementRef?.current);
    setLongPressed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLongPressed(false);
    setRightClicked(false);
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    if (desktopMatches && type?.organizationId) {
      setAnchorEl(elementRef?.current);
      setRightClicked(true);
    }
  };

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleClose}
        />
      )}

      <LongPressTypesItemMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        typeId={type?.id}
      />

      {desktopMatches ? (
        <TypeItemContainer
          ref={elementRef}
          lastindex={lastIndex}
          currentypeid={currentTypeId}
          id={id}
          longPressed={open}
          currentTheme={currentTheme}
          onClick={handleClick}
          onContextMenu={handleRightClick}
        >
          <Grid py={1} container gap={1} alignItems="center">
            <Grid item alignItems="center" display="flex" position="relative">
              {getIconCreationType(organizationId, globalFontSize)}
              {isFavorite &&
                getSvgIcon(
                  "STAR_FILLED",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
            </Grid>
            <TypeNameGridContainer item xs={7}>
              <ItemName isSelected={currentTypeId === id}>{name}</ItemName>
            </TypeNameGridContainer>
            <Grid item xs>
              <SecondaryText>{creationType}</SecondaryText>
            </Grid>
            <Grid item display="flex" alignItems="center">
              {getSvgIcon(
                "ARROW_RIGHT",
                iconSize,
                iconSize,
                theme.palette.primary.main
              )}
            </Grid>
          </Grid>
        </TypeItemContainer>
      ) : (
        <ItemContainer longPressed={open} currentTheme={currentTheme}>
          <Item
            key={`resource-${id}`}
            ref={elementRef}
            onClick={handleClick}
            {...handlers}
          >
            <ListItemIcon>
              {getIconCreationType(organizationId, globalFontSize)}
            </ListItemIcon>
            <ItemName>{name}</ItemName>
            {getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
          </Item>
        </ItemContainer>
      )}
    </ErrorHandling>
  );
};

export default FavoriteTypeItem;
