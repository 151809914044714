import dayjs from "dayjs";
import { RESOURCE_CATEGORIES, measurementDisplay } from "./utils";

export const validateResourceForm = (resourceInput, t) => {
  const error = {};
  let firstError;

  if (!resourceInput?.functionId) {
    error.functionId = "Function is required!";

    firstError = t("FUNCTION_VALIDATION");
  }

  if (resourceInput?.category !== "LOCATION") {
    if (!resourceInput?.typeId) {
      error.typeId = "Type is required!";

      if (!firstError) {
        firstError = t("TYPE_VALIDATION");
      }
    }

    if (!resourceInput?.parentId) {
      error.parentId = "Parent is required!";

      if (!firstError) {
        firstError = t("PARENT_VALIDATION");
      }
    }
  }

  if (!resourceInput?.name) {
    error.name = "Resource name is required!";

    if (!firstError) {
      firstError = t("RESOURCE_NAME_VALIDATION");
    }
  }

  const invalidCharacteristics = resourceInput?.characteristics?.filter(
    (chr) => chr.required && !Boolean(chr.value)
  );

  if (invalidCharacteristics?.length > 0) {
    const firstInvalidCharacteristic = invalidCharacteristics[0];
    error.characteristics = `${firstInvalidCharacteristic?.name} must be filled!`;

    if (!firstError) {
      firstError =
        t(`${firstInvalidCharacteristic?.name}`) +
        t("CHARACTERISTICS_VALIDATION");
    }
  }

  return {
    error,
    firstError,
  };
};

export const checkResourceChanged = (
  originalResourceInput,
  currentResourceInput,
  region
) => {
  const {
    parentId: originalParentId,
    category: originalCategory,
    functionId: originalFunctionId,
    typeId: originalTypeId,
    name: originalName,
    displayId: originalDisplayId,
    description: originalDescription,
    characteristics: originalCharacteristics,
  } = originalResourceInput;

  const {
    parentId: currentParentId,
    category: currentCategory,
    functionId: currentFunctionId,
    typeId: currentTypeId,
    name: currentName,
    displayId: currentDisplayId,
    description: currentDescription,
    characteristics: currentCharacteristics,
  } = currentResourceInput;

  // Checked with one equal sign because we change its type from number to string
  const isParentIdChanged =
    (Boolean(originalParentId) || Boolean(currentParentId)) &&
    originalParentId !== currentParentId;
  const isResourceCategoryChanged =
    (Boolean(originalCategory) || Boolean(currentCategory)) &&
    originalCategory !== currentCategory;
  const isFunctionIdIdChanged =
    (Boolean(originalFunctionId) || Boolean(currentFunctionId)) &&
    Number(originalFunctionId) !== Number(currentFunctionId);
  const isTypeIdChanged =
    (Boolean(originalTypeId) || Boolean(currentTypeId)) &&
    originalTypeId !== currentTypeId;
  const isNameChanged =
    (Boolean(originalName) || Boolean(currentName)) &&
    originalName !== currentName;
  const isDisplayIdChanged =
    (Boolean(originalDisplayId) || Boolean(currentDisplayId)) &&
    originalDisplayId !== currentDisplayId;
  const isDescriptionChanged =
    (Boolean(originalDescription) || Boolean(currentDescription)) &&
    originalDescription !== currentDescription;

  const currentCharacteristicsWithValues = currentCharacteristics.filter(
    (c) => c.value
  );
  let areCharacteristicsChanged = false;

  if (
    originalCharacteristics.length !== currentCharacteristicsWithValues.length
  ) {
    areCharacteristicsChanged = true;
  } else {
    areCharacteristicsChanged = originalCharacteristics.some(
      (originalCharacteristic) => {
        const existingCharacteristic = currentCharacteristicsWithValues.find(
          (currCharacteristic) =>
            currCharacteristic.id === originalCharacteristic.id
        );

        if (!existingCharacteristic) {
          return true;
        }

        if (existingCharacteristic.value || originalCharacteristic.value) {
          if (existingCharacteristic.dataType === "INTEGER") {
            const convertedOriginalCharacteristicValue =
              originalCharacteristic.value
                ? measurementDisplay({
                    value: originalCharacteristic.value,
                    unit: existingCharacteristic.measurementUnit,
                    region: region,
                    reverse: false,
                  }).value
                : originalCharacteristic.value;

            return (
              Number(existingCharacteristic.value) !==
              Number(convertedOriginalCharacteristicValue)
            );
          } else {
            return (
              existingCharacteristic.value !== originalCharacteristic.value
            );
          }
        }

        return false;
      }
    );
  }

  return (
    isParentIdChanged ||
    isResourceCategoryChanged ||
    isFunctionIdIdChanged ||
    isTypeIdChanged ||
    isNameChanged ||
    isDisplayIdChanged ||
    isDescriptionChanged ||
    areCharacteristicsChanged
  );
};

export const transformResourceResponse = (resourceData) => {
  const allCharacteristics = [
    ...resourceData.characteristics.filter((c) => c.value),
    ...(resourceData.type?.characteristics.filter((tChar) => {
      return !resourceData.characteristics.some(
        (rChar) => rChar.id === tChar.id
      );
    }) ?? []),
  ];

  const mappedCharacteristics = allCharacteristics.map((char) => {
    let characteristicValue = char?.value;

    if (
      char?.id === 160 ||
      char?.name === "LIFECYCLE_DATE" ||
      char?.dataType === "DATETIME"
    ) {
      characteristicValue = dayjs(char?.value);
      // console.log(characteristicValue);
    }

    return {
      ...char,
      value: characteristicValue,
    };
  });

  return {
    id: resourceData.id,
    name: resourceData.name,
    description: resourceData.description,
    typeId: resourceData.type?.id,
    displayId: resourceData.displayId,
    functionId: resourceData.function?.id,
    characteristics: mappedCharacteristics,
    parentId: resourceData.parent?.id,
    category: resourceData.function?.category,
    hasTypeChanged: false,
  };
};

export const transformResourceInputRequest = (
  resourceInput,
  region,
  isEditMode
) => {
  const mappedCharacteristics = resourceInput.characteristics
    .filter((chr) => chr.editable && chr.value)
    .map((chr) => {
      let characteristicValue;

      if (chr?.value) {
        characteristicValue = chr?.measurementUnit
          ? measurementDisplay({
              value: chr?.value,
              unit: chr?.measurementUnit,
              region: region,
              reverse: true,
            }).value
          : chr?.value;
      }

      if (chr?.name === "LIFECYCLE_DATE" || chr?.dataType === "DATETIME") {
        characteristicValue = chr?.value ? dayjs(chr?.value).format() : null;
      }

      return {
        id: chr.id,
        value: characteristicValue,
        editable: chr.editable,
      };
    });

  return {
    name: resourceInput.name,
    description: resourceInput.description,
    typeId: resourceInput.typeId,
    displayId: resourceInput.displayId,
    functionId: resourceInput.functionId,
    category: resourceInput.category,
    parentId: resourceInput.parentId,
    characteristics: mappedCharacteristics,
  };
};

export const setUpCharacteristics = (
  typeCharacteristics,
  setTemplateCharacteristicIds,
  characteristicDefinitions,
  region
) => {
  let characteristics = [];

  const typeCharacteristicIds = typeCharacteristics.map((t) => t.id);
  const characteristicIds = new Set([
    ...typeCharacteristicIds,
    ...setTemplateCharacteristicIds,
  ]);

  characteristicIds.forEach((id) => {
    const characteristicDefinition = characteristicDefinitions.find(
      (definition) => definition.id === id
    );
    const typeCharacteristic = typeCharacteristics.find(
      (typeChar) => typeChar.id === id
    );

    let characteristic;
    if (typeCharacteristic) {
      characteristic = {
        ...characteristicDefinition,
        editable: typeCharacteristic.editable,
        value:
          typeCharacteristic.value && characteristicDefinition.measurementUnit
            ? measurementDisplay({
                value: typeCharacteristic.value,
                unit: characteristicDefinition.measurementUnit,
                region,
                reverse: false,
              }).value
            : typeCharacteristic.value,
      };
    } else {
      characteristic = {
        ...characteristicDefinition,
        value: null,
        editable: true,
      };
    }

    characteristics.push(characteristic);
  });

  return characteristics;
};

export const groupCharacteristics = (characteristics, setTemplates) => {
  let tempCharacteristics = characteristics;
  let groupedCharacteristicsBySetTemplate = [];

  if (setTemplates) {
    groupedCharacteristicsBySetTemplate = setTemplates.map((setTemplate) => {
      const setTemplateCharacteristics = setTemplate.characteristics;
      const characteristicsBySetTemplate = characteristics.filter(
        (characteristic) => {
          if (
            setTemplateCharacteristics.some((id) => id === characteristic.id)
          ) {
            tempCharacteristics = tempCharacteristics.filter(
              (tmpChar) => tmpChar.id !== characteristic.id
            );
            return true;
          } else {
            return false;
          }
        }
      );

      return {
        id: setTemplate.id,
        name: setTemplate.name,
        orderPriority: setTemplate.orderPriority,
        characteristics: characteristicsBySetTemplate,
      };
    });
  }

  return {
    setTemplates: groupedCharacteristicsBySetTemplate,
    other: tempCharacteristics,
  };
};

export const mergeCharacteristics = (
  resourceCharacteristics,
  typeCharacteristics,
  characteristicDefinitions
) => {
  const characteristics = resourceCharacteristics
    .concat(typeCharacteristics)
    .reduce((acc, current) => {
      const characteristicDefinition = characteristicDefinitions?.find(
        (c) => c.id === current?.id
      );
      const characteristic = acc.find((c) => c.id === current?.id);
      if (!characteristic) {
        return acc.concat([
          {
            ...characteristicDefinition,
            ...current,
          },
        ]);
      } else {
        return acc;
      }
    }, []);

  return characteristics;
};

export const LOCATION_DETAILS = {
  [RESOURCE_CATEGORIES.RACK]: "LOCATION",
  [RESOURCE_CATEGORIES.HARDWARE_ASSET]: "LOCATION",
  [RESOURCE_CATEGORIES.LOCATION]: "PARENT",
  DEFAULT: "LOCATION",
};

export const ALLOWED_CHILDREN_CATEGORIES = {
  [RESOURCE_CATEGORIES.LOCATION]: [
    RESOURCE_CATEGORIES.LOCATION,
    RESOURCE_CATEGORIES.RACK,
    RESOURCE_CATEGORIES.HARDWARE_ASSET,
  ],
  [RESOURCE_CATEGORIES.RACK]: [
    RESOURCE_CATEGORIES.RACK,
    RESOURCE_CATEGORIES.HARDWARE_ASSET,
  ],
  [RESOURCE_CATEGORIES.HARDWARE_ASSET]: [RESOURCE_CATEGORIES.HARDWARE_ASSET],
  "": [RESOURCE_CATEGORIES.LOCATION],
};

export const DISABLED_CHILDREN_CATEGORIES = {
  [RESOURCE_CATEGORIES.LOCATION]: [],
  [RESOURCE_CATEGORIES.RACK]: [RESOURCE_CATEGORIES.LOCATION],
  [RESOURCE_CATEGORIES.HARDWARE_ASSET]: [
    RESOURCE_CATEGORIES.RACK,
    RESOURCE_CATEGORIES.LOCATION,
  ],
  "": [RESOURCE_CATEGORIES.RACK, RESOURCE_CATEGORIES.HARDWARE_ASSET],
};

export const ALLOWED_PARENT_CATEGORIES = {
  [RESOURCE_CATEGORIES.LOCATION]: [RESOURCE_CATEGORIES.LOCATION],
  [RESOURCE_CATEGORIES.RACK]: [
    RESOURCE_CATEGORIES.LOCATION,
    RESOURCE_CATEGORIES.RACK,
  ],
  [RESOURCE_CATEGORIES.HARDWARE_ASSET]: [
    RESOURCE_CATEGORIES.HARDWARE_ASSET,
    RESOURCE_CATEGORIES.RACK,
    RESOURCE_CATEGORIES.LOCATION,
  ],
  "": [
    RESOURCE_CATEGORIES.LOCATION,
    RESOURCE_CATEGORIES.HARDWARE_ASSET,
    RESOURCE_CATEGORIES.RACK,
  ],
};
