import { Backdrop, TableCell, useTheme } from "@mui/material";
import {
  FlexBox,
  FlexContainer,
  NameTableCell,
  SelectedTableRow,
  TableCellText,
} from "../../styles/assets/asset-list/AssetListTable.styles";
import { getIconCreationTypeDesktop } from "./FavoriteTypesAccordion";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import LongPressTypesItemMenu from "./LongPressTypesItemMenu";
import { useLocation } from "react-router-dom";
import { selectUser } from "../../../store/slices/authSlice";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import ErrorHandling from "../../common/ErrorHandling";
import FavoriteStarComponent from "../../assets/asset-list/FavoriteStarComponent";

const TypeListTableRow = ({ type, currentTypeId, handleClickItem }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // Queries
  const {
    data: allFunctionsData,
    isLoading,
    isError,
  } = useGetAllFunctionsQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [rightClicked, setRightClicked] = useState(false);

  // Refs
  const elementRef = useRef();

  // Other variables
  const { id, name, functionId, organizationId } = type;

  const createdBy = organizationId
    ? getTranslation("USER_CREATED_TYPE", t, i18n)
    : getTranslation("SYSTEM_CREATED_TYPE", t, i18n);

  const iconSize = globalFontSize * 1.2;
  const open = rightClicked;
  const isInFavorites = pathname?.includes("favorites");

  // Handlers
  const handleClose = () => {
    setAnchorEl(null);
    setRightClicked(false);
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    if (type?.organizationId) {
      setAnchorEl(elementRef?.current);
      setRightClicked(true);
    }
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  // Other variables
  const resourceFunction = getFunction(functionId);

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleClose}
        />
      )}

      <LongPressTypesItemMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        typeId={type?.id}
      />

      <SelectedTableRow
        isselected={currentTypeId === id}
        key={id}
        ref={elementRef}
        longPressed={open}
        currentTheme={currentTheme}
        onClick={() => handleClickItem(id)}
        onContextMenu={handleRightClick}
      >
        <TableCell>
          <FlexBox>
            {getIconCreationTypeDesktop(organizationId, globalFontSize, theme)}

            {isInFavorites && <FavoriteStarComponent right="0" />}
          </FlexBox>
        </TableCell>
        <NameTableCell>
          <TableCellText isSelected={currentTypeId === id}>
            {name}
          </TableCellText>
        </NameTableCell>
        <TableCell>
          <TableCellText>
            {getTranslation(resourceFunction?.category, t, i18n)}
          </TableCellText>
        </TableCell>
        <TableCell>
          <TableCellText>{createdBy}</TableCellText>
        </TableCell>
        <TableCell>
          <FlexContainer>
            {getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
          </FlexContainer>
        </TableCell>
      </SelectedTableRow>
    </ErrorHandling>
  );
};

export default TypeListTableRow;
