import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useOrientation from "../../../hooks/useOrientation";
import {
  selectColumnViewTrigger,
  selectIsDrawerOpen,
  selectIsSearchOpen,
  selectPageView,
  selectSearchTerm,
  selectSortingOption,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectColumns,
  selectFullWidthColumnId,
  selectOverflowX,
  selectParentId,
  selectTrail,
  setColumns,
  setTrail,
  sortColumns,
} from "../../../store/slices/columnViewSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import ListColumnViewCard from "./ListColumnViewCard";
import { baseApiSlice } from "../../../store/slices/api/baseApiSlice";
import { useGetResourcesQuery } from "../../../store/slices/api/assetManagementSlice";
import { ListColumnViewContainer } from "../../styles/assets/asset-list/ListColumnView.styles";
import ErrorHandling from "../../common/ErrorHandling";

const ListColumnView = ({ favorites }) => {
  // General hooks
  const dispatch = useDispatch();
  const gridRef = useRef(null);

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const user = useSelector(selectUser);
  const columns = useSelector(selectColumns);
  const sortingOption = useSelector(selectSortingOption);
  const searchTerm = useSelector(selectSearchTerm);
  const isSearchOpen = useSelector(selectIsSearchOpen);
  const trail = useSelector(selectTrail);
  const parentId = useSelector(selectParentId);
  const currentpageview = useSelector(selectPageView);
  const fullWidthColumnId = useSelector(selectFullWidthColumnId);
  const overflowX = useSelector(selectOverflowX);
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  const columnViewTrigger = useSelector(selectColumnViewTrigger);

  // Queries
  const { data: userRoles, isError: isErrorUserRoles } =
    useUserRolePermissionsQuery({
      userId: user.id,
      organizationId: user?.organizations?.find((o) => o.default).id,
    });

  const { data: resourceChildrenData, isError: isErrorChildren } =
    useGetResourcesQuery(
      {
        parentId,
        organizationId: user?.organizations?.find((o) => o.default).id,
      },
      {
        skip: !Boolean(parentId),
      }
    );

  // Effects
  useEffect(() => {
    let finalColumns = columns;
    let finalTrail = [...trail];

    const clickedColumn =
      finalColumns
        .filter((column) => column.columnType !== "search")
        .find((c) => c.items.some((item) => item.id === parentId)) ||
      finalColumns[0];

    // Made condition to load the first column of top level parents
    if (finalColumns?.length <= 0) {
      const newColumn = {
        parentId: null,
        items: [],
        level: 1,
        columnType: "list",
      };

      dispatch(
        setColumns({
          columns: [newColumn],
          sortBy: sortingOption.value,
          order: sortingOption.order,
          favorites,
        })
      );

      dispatch(setTrail([parentId]));

      return;
    }

    // Responsible for returning back to low level columns
    if (clickedColumn?.level < finalColumns.length) {
      const parentColumns =
        finalColumns.filter((column) => column.level < clickedColumn.level) ||
        [];

      finalColumns = [
        ...parentColumns,
        { ...clickedColumn, level: parentColumns.length + 1 },
      ];

      finalTrail = [...finalColumns.map((column) => column.parentId)];
    }

    // Responsible for going forward in column level
    if (
      !finalColumns.some((column) => column.parentId === clickedColumn.parentId)
    ) {
      finalColumns.push(clickedColumn);

      if (!finalTrail.some((item) => item === clickedColumn?.parentId)) {
        finalTrail.push(clickedColumn?.parentId);
      }
    }

    const newColumn = {
      parentId,
      level: finalColumns.length + 1,
      items: resourceChildrenData?.data ?? [],
      columnType: "list",
    };

    if (
      !finalColumns.some((column) => column?.parentId === newColumn.parentId)
    ) {
      finalColumns.push(newColumn);

      if (!finalTrail.some((item) => item === newColumn.parentId)) {
        finalTrail.push(newColumn.parentId);
      }
    }

    dispatch(
      setColumns({
        columns: finalColumns,
        sortBy: sortingOption.value,
        order: sortingOption.order,
        favorites,
      })
    );

    if (clickedColumn.level <= 1) {
      dispatch(setTrail([parentId]));
    } else {
      dispatch(
        setTrail([
          ...finalTrail.filter(
            (id) => !clickedColumn.items.some((item) => item.id === id)
          ),
          parentId,
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId, resourceChildrenData, columnViewTrigger]);

  useEffect(() => {
    if (Boolean(searchTerm) && isSearchOpen) {
      const filteredColumns = columns?.filter((column) => {
        return (
          column.items?.filter((item) => {
            const { name, displayId } = item;
            const combination = displayId || name;
            return combination.toLowerCase().includes(searchTerm.toLowerCase());
          }).length > 0
        );
      });

      const allItems = [];
      filteredColumns.forEach((column) =>
        allItems.push(
          ...column.items?.filter((item) => {
            const { id, name, displayId } = item;
            const combination = displayId || name;
            return (
              !allItems.some((all) => all.id === id) &&
              combination.toLowerCase().includes(searchTerm.toLowerCase())
            );
          })
        )
      );

      const newColumn = {
        parentId: null,
        items: allItems,
        level: 1,
        columnType: "search",
      };

      dispatch(
        setColumns({
          columns: [newColumn],
          sortBy: sortingOption.value,
          order: sortingOption.order,
          favorites,
        })
      );
    } else {
      // In case search is opened and was clicked resource to not reset columns
      if (
        !isSearchOpen &&
        columns.length !== 0 &&
        columns[0].columnType === "search"
      ) {
        dispatch(baseApiSlice.util.resetApiState());
        const newColumn = {
          items: [],
          parentId: null,
          columnType: "list",
          level: 1,
        };

        dispatch(
          setColumns({
            columns: [newColumn],
            sortBy: sortingOption.value,
            order: sortingOption.order,
            favorites,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, isSearchOpen]);

  // Takes care of sorting and ordering
  useEffect(() => {
    dispatch(
      sortColumns({
        sortBy: sortingOption.value,
        order: sortingOption.order,
        favorites,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingOption.value, sortingOption.order, currentpageview]);

  // Takes care when the screen is overflowed and scroll to right
  useEffect(() => {
    const gridContainer = gridRef.current;

    if (gridContainer) {
      const isOverflowing =
        gridContainer.scrollWidth > gridContainer.clientWidth;

      if (isOverflowing) {
        gridContainer.scrollLeft = gridContainer.scrollWidth;
      }
    }
  }, [
    columns,
    sortingOption.value,
    searchTerm,
    orientation,
    fullWidthColumnId,
    isDrawerOpen,
  ]);

  const indexFullWidthId = columns?.findIndex(
    (column) => column.parentId === fullWidthColumnId
  );

  return (
    <ErrorHandling
      isLoading={false}
      isError={isErrorChildren || isErrorUserRoles}
    >
      <ListColumnViewContainer ref={gridRef} overflowx={overflowX} container>
        {columns
          ?.filter((column) => {
            if (!isSearchOpen) {
              return true;
            }

            return (
              column.items?.filter((item) => {
                const { name, displayId } = item;
                const combination = displayId || name;

                return combination
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase());
              }).length > 0
            );
          })
          ?.slice(
            0,
            indexFullWidthId === -1 ? columns?.length : indexFullWidthId + 1
          )
          ?.map((column, index) => (
            <ListColumnViewCard
              key={column.parentId}
              column={column}
              favorites={favorites}
              userRoles={userRoles}
            />
          ))}
      </ListColumnViewContainer>
    </ErrorHandling>
  );
};

export default ListColumnView;
