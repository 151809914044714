import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetTypeFavoritesQuery } from "../../../store/slices/api/typesApiSlice";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import {
  LoadMoreWrapper,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/types/type-list/TypeListPage.styles";
import ErrorHandling from "../../common/ErrorHandling";
import {
  DEFAULT_INDEX,
  DEFAULT_SORTING_OPTION,
  selectGlobalFontSize,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  Grid,
  TableBody,
  TableContainer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ListTable } from "../../styles/assets/asset-list/AssetListTable.styles";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import InfiniteScrollLoader from "../../InfiniteScrollLoader";
import {
  NoDataTextContainer,
  SecondaryContrastButton,
} from "../../styles/general/General.styles";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import { getComparator, stableSort } from "../../../util/reports-utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { useNavigate } from "react-router-dom";
import { CreateAssetButton } from "../../styles/assets/AssetList.styles";
import { getSvgIcon } from "../../../util/icons";
import TypeListTableRow from "./TypeListTableRow";
import TypeListInlineViewItem from "./TypeListInlineViewItem";
import { useDispatch } from "react-redux";
import {
  selectIndex,
  selectItems,
  setIndex,
  setItems,
} from "../../../store/slices/typeSlice";

const PAGE_SIZE = 20;
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "NAME",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "CATEGORY",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: false,
    label: "resource.createdBy",
  },
];

export const getIconCreationType = (organizationId, globalFontSize, theme) => {
  const iconSize = globalFontSize * 1.25;

  return organizationId
    ? getSvgIcon("PROFILE", iconSize, iconSize, theme.palette.primary.main)
    : getSvgIcon(
        "MODEL_LIBRARY",
        iconSize,
        iconSize,
        theme.palette.primary.main
      );
};

export const getIconCreationTypeDesktop = (
  organizationId,
  globalFontSize,
  theme
) => {
  const iconSize = globalFontSize * 2;

  return organizationId
    ? getSvgIcon("PROFILE", iconSize, iconSize, theme.palette.primary.main)
    : getSvgIcon(
        "MODEL_LIBRARY",
        iconSize,
        iconSize,
        theme.palette.primary.main
      );
};

const FavoriteTypesAccordion = ({ handleClickItem, currentTypeId }) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const user = useSelector(selectUser);
  const index = useSelector(selectIndex);
  const items = useSelector(selectItems);

  // States
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [expand, setExpand] = useState(true);
  const [fetchingItems, setFetchingItems] = useState(false);
  const [sortingOption] = useState(DEFAULT_SORTING_OPTION);

  // Other variables
  const organizationId = user?.organizations.find((o) => o.default)?.id;
  const iconSize = globalFontSize * 1.2;

  // Queries
  const {
    data: typeFavoritesData,
    isLoading: isLoadingTypeFavorites,
    isFetching,
    isError: isErrorFavorites,
  } = useGetTypeFavoritesQuery({
    organizationId,
    index,
    size: PAGE_SIZE,
    order: sortingOption.order,
    sortBy: getSortByType(),
  });

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId: user?.organizations.find((o) => o.default).id,
  });

  // Other variables
  const favorites = typeFavoritesData?.data;
  const totalRecord = typeFavoritesData?.totalRecord;
  const fetching = isFetching || fetchingItems;
  const pageCount = typeFavoritesData?.pageCount;

  const sortedItems = useMemo(() => {
    return stableSort(items, getComparator(order, orderBy));
  }, [order, orderBy, items]);

  // Handlers
  function getSortByType() {
    if (sortingOption.type === "displayIdAndNameCombination") {
      return "name";
    } else if (sortingOption.type === "favoured") {
      return "createdAt";
    } else {
      return sortingOption.type;
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const fetchMoreData = () => {
    setFetchingItems(true);
    setTimeout(() => {
      dispatch(setIndex(index + 1));
      setFetchingItems(false);
    }, 1000);
  };

  const handleNavigateToLibrary = () => {
    if (desktopMatches) {
      navigate(`/library`);
    } else {
      navigate(`/library?direction=${transitionDirections.RIGHT_TO_LEFT}`);
    }
  };

  // Effects
  useEffect(() => {
    dispatch(setIndex(DEFAULT_INDEX));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (favorites && allFunctionsData) {
      if (index <= 1) {
        dispatch(
          setItems([
            ...favorites?.map((f) => {
              return {
                ...f.type,
              };
            }),
          ])
        );
      } else {
        dispatch(
          setItems([
            ...items,
            ...favorites
              .filter((f) => !items?.some((i) => i?.id === f?.type?.id))
              ?.map((f) => {
                return {
                  ...f.type,
                };
              }),
          ])
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites, allFunctionsData, index]);

  return (
    <ErrorHandling
      isLoading={isLoadingTypeFavorites}
      isError={isErrorFavorites}
    >
      <StyledAccordion
        id="library-accordion"
        expanded={expand}
        onChange={toggleExpand}
      >
        <StyledAccordionSummary
          expandIcon={getSvgIcon(
            "ARROW_RIGHT",
            iconSize,
            iconSize,
            theme.palette.secondary.light
          )}
        >
          <SecondaryText>{`${getTranslation("LIBRARY", t, i18n)} (${
            sortedItems.length
          }/${totalRecord})`}</SecondaryText>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {sortedItems.length > 0 ? (
            desktopMatches ? (
              <TableContainer>
                <ListTable sx={{ minWidth: "0px" }} aria-label="simple table">
                  <EnhancedTableHead
                    headCells={headCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {sortedItems.map((type) => (
                      <TypeListTableRow
                        key={type?.id}
                        type={type}
                        currentTypeId={currentTypeId}
                        handleClickItem={handleClickItem}
                      />
                    ))}
                  </TableBody>
                </ListTable>
              </TableContainer>
            ) : (
              <Grid
                container
                display="flex"
                justifyContent="start"
                columns={36}
                rowGap={tabletMatches ? 2 : 0}
              >
                {sortedItems?.map((row) => (
                  <TypeListInlineViewItem
                    key={row?.id}
                    row={row}
                    handleClickItem={handleClickItem}
                  />
                ))}
              </Grid>
            )
          ) : (
            <NoDataTextContainer>
              <SecondaryText align="center">
                {getTranslation("NO_FAVORITE_LIBRARY", t, i18n)}
              </SecondaryText>
              <CreateAssetButton onClick={handleNavigateToLibrary}>
                {getTranslation("OPEN_LIBRARY", t, i18n)}
              </CreateAssetButton>
            </NoDataTextContainer>
          )}
          {fetching ? (
            <InfiniteScrollLoader />
          ) : (
            <>
              {index < pageCount && (
                <LoadMoreWrapper>
                  <SecondaryContrastButton
                    onClick={fetchMoreData}
                    sx={{
                      width: "auto",
                      margin: "auto",
                    }}
                  >
                    {getTranslation("LOAD_MORE", t, i18n)}
                  </SecondaryContrastButton>
                </LoadMoreWrapper>
              )}
            </>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </ErrorHandling>
  );
};

export default FavoriteTypesAccordion;
