import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  setIsActionButtonDisabled,
} from "../../../store/slices/appSlice";
import { messageError } from "../../../util/notification";
import {
  ALLOWED_ATTACHMENTS_EXTENSIONS,
  DEFAULT_ATTACHMENT_SIZE,
  getTranslation,
} from "../../../util/utils";
import { Box, MenuItem, TextField, useTheme } from "@mui/material";
import {
  AddResourceAttachmentPageContainer,
  AttachFileButton,
  DescriptionTextFieldContainer,
} from "../../styles/assets/asset-attachments/AddResourceAttachmentPage.styles";
import { PrimaryText } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { CenteredSecondaryText } from "../../styles/general/General.styles";
import fileTypeChecker from "file-type-checker";
import { getSvgIcon } from "../../../util/icons";

const ResourceAttachmentForm = ({
  resourceAttachmentInput,
  setResourceAttachmentInput,
  name,
  setName,
  setExtension,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Handlers
  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > DEFAULT_ATTACHMENT_SIZE) {
        messageError(
          getTranslation("TOO_LARGE_ATTACHMENT_VALIDATION", t, i18n)
        );

        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const buffer = reader.result;
        const uintArray = new Uint8Array(buffer);

        const isAttachmentValid = fileTypeChecker.validateFileType(
          uintArray,
          ALLOWED_ATTACHMENTS_EXTENSIONS
        );

        if (!isAttachmentValid) {
          messageError(getTranslation("NOT_ALLOWED_ATTACHMENT_TYPE", t, i18n));
          dispatch(setIsActionButtonDisabled(true));
          setResourceAttachmentInput({ ...resourceAttachmentInput, name: "" });
          setName("");
        } else {
          dispatch(setIsActionButtonDisabled(false));
        }
      };

      reader.readAsArrayBuffer(file);

      const url = URL.createObjectURL(file);

      setResourceAttachmentInput({
        ...resourceAttachmentInput,
        selectedFile: url,
        file,
        mimeType: file.name.substring(file.name.lastIndexOf(".")),
      });

      setName(file?.name?.substring(0, file?.name?.lastIndexOf(".")));
      setExtension(file?.name?.substring(file?.name?.lastIndexOf(".")));

      dispatch(setIsActionButtonDisabled(false));
    }
  };

  const handleChangeName = (e) => {
    const newName = e.target.value;

    if (!newName) {
      dispatch(setIsActionButtonDisabled(true));
    } else {
      dispatch(setIsActionButtonDisabled(false));
    }

    setName(newName);
  };

  const handleChangeDescription = (e) => {
    const newDescription = e.target.value;

    setResourceAttachmentInput({
      ...resourceAttachmentInput,
      description: newDescription,
    });
  };

  // Other variables
  const iconSize = globalFontSize * 1.5;

  return (
    <>
      <AddResourceAttachmentPageContainer>
        <MenuItem component="label" onChange={handleFileChange}>
          <AttachFileButton variant="square" id="image-avatar">
            <input data-testid="file-input" hidden type="file" />
            {getSvgIcon(
              "ATTACHMENT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </AttachFileButton>
        </MenuItem>
      </AddResourceAttachmentPageContainer>
      {resourceAttachmentInput.selectedFile ? (
        <CenteredSecondaryText>{name}</CenteredSecondaryText>
      ) : (
        <PrimaryText align="center">
          {getTranslation("UPLOAD_FILE", t, i18n)}
        </PrimaryText>
      )}
      <Box>
        <TextField
          inputProps={{ "data-testid": "name-field" }}
          label={getTranslation("NAME", t, i18n)}
          value={name}
          onChange={handleChangeName}
        />
      </Box>

      <DescriptionTextFieldContainer>
        <TextField
          inputProps={{ "data-testid": "description-field" }}
          label={getTranslation("DESCRIPTION", t, i18n)}
          value={resourceAttachmentInput.description}
          onChange={handleChangeDescription}
        />
      </DescriptionTextFieldContainer>
    </>
  );
};

export default ResourceAttachmentForm;
