import { useCallback, useEffect, useState } from "react";
import { selectUser } from "../../store/slices/authSlice";
import { useSelector } from "react-redux";
import { useGetUserInvitationsCheckQuery } from "../../store/slices/api/userManagementSlice";
import { useNavigate } from "react-router-dom";
import LoadingSpinnerOverlay from "./LoadingSpinnerOverlay";

const AppAccess = ({ children }) => {
  // General hooks
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);

  // State
  const [loading, setLoading] = useState(true);

  // Other variables
  const invalidUser = !user?.firstName || !user?.lastName;

  // Queries
  const { data, isLoading, error } = useGetUserInvitationsCheckQuery(
    { email: user?.email, status: "ACCEPTED" },
    { skip: invalidUser || user?.organizations?.length > 0 }
  );

  // Handlers
  const handleRedirection = useCallback(() => {
    if (user?.id) {
      if (invalidUser) {
        navigate("/onboarding");
        setLoading(false);

        return;
      }

      const organizations = user?.organizations;

      if (
        (!organizations || organizations.length <= 0) &&
        error?.status === 404
      ) {
        navigate("/organization-onboarding");
        setLoading(false);

        return;
      }

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.organizations?.length, data, error?.status]);

  // Effects
  useEffect(() => {
    handleRedirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRedirection]);

  if (loading || isLoading) {
    return <LoadingSpinnerOverlay />;
  }

  return <>{children}</>;
};

export default AppAccess;
