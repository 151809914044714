import React, { useState } from "react";
import { DeleteButton } from "../../styles/assets/CrudForm.styles";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { messageError, messageSuccess } from "../../../util/notification";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import { useGetResourcesQuery } from "../../../store/slices/api/assetManagementSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { useDispatch } from "react-redux";
import { setTabletTabValue } from "../../../store/slices/assetListSlice";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectPageView,
} from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";
import { useTheme } from "@mui/material";
import { setParentId } from "../../../store/slices/columnViewSlice";

const DeleteAsset = (props) => {
  const {
    parentId,
    resourceid,
    category,
    organizationId,
    deleteResource,
    handleClose,
  } = props;

  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const pageView = useSelector(selectPageView);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: resourceChildrenData,
    isLoading,
    isError,
  } = useGetResourcesQuery(
    {
      parentId: resourceid,
      organizationId: organizationId,
    },
    { skip: !Boolean(resourceid) }
  );

  // States
  const [open, setOpen] = useState(false);

  // Handlers
  const handleDeleteConfirm = () => {
    setOpen(true);
  };

  const deleteConfirm = async () => {
    try {
      if (pageView !== "column") {
        navigate(`/resources${parentId ? `/${parentId}` : ""}`);
      }

      if (handleClose) {
        handleClose();
      }

      await deleteResource({
        resourceid,
        organizationId,
        category,
      }).unwrap();

      dispatch(setParentId(parentId));
      dispatch(setTabletTabValue(0));

      messageSuccess(getTranslation("ASSET_DELETED_SUCCESSFULLY", t, i18n));
    } catch (error) {
      messageError(getTranslation("ASSET_DELETE_FAILED", t, i18n));
    }
  };

  // Other variables
  const haveChildren = resourceChildrenData?.totalRecord <= 0;
  const alert = {
    content: haveChildren
      ? getTranslation("ASSET_DELETE_MESSAGE", t, i18n)
      : getTranslation("ASSET_DELETE_WITH_CHILDREN_MESSAGE", t, i18n),
    confirmTitle: getTranslation("DELETE_ASSET", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const iconSize = globalFontSize;

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      <>
        <ConfirmAlert
          isOpen={open}
          setIsOpen={setOpen}
          alert={alert}
          handleConfirm={deleteConfirm}
          label="delete"
        />
        <div>
          <DeleteButton
            id="delete-button"
            data-testid="delete-button"
            onClick={handleDeleteConfirm}
            startIcon={getSvgIcon(
              "DELETE",
              iconSize,
              iconSize,
              theme.palette.error.main
            )}
            variant="text"
            color="error"
          >
            {getTranslation("DELETE_ASSET", t, i18n)}
          </DeleteButton>
        </div>
      </>
    </ErrorHandling>
  );
};

export default DeleteAsset;
