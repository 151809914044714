import { Box, Paper, useTheme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useState } from "react";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import AddLocalImageDialog from "../asset-form/AddLocalImageDialog";
import { selectCurrentResourceId } from "../../../store/slices/longPressSlice";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const AddLocalImageButton = ({ localResourceImages, imageCategory, mode }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const navigate = useNavigate();
  const { resourceid: resourceId } = useParams();

  // Selectors
  const parentId = useSelector(selectParentId);
  const currentResourceId = useSelector(selectCurrentResourceId);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [openImageDialog, setOpenImageDialog] = useState(false);

  // Other variables
  const calculatedResourceId = currentResourceId || resourceId || parentId;
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleImageAddClick = () => {
    if (mobileMatches) {
      navigate(
        `/resources${
          resourceId ? `/${resourceId}` : ""
        }/add-local-image?direction=${
          transitionDirections.RIGHT_TO_LEFT
        }&imageCategory=${imageCategory}`
      );
    } else {
      setOpenImageDialog(true);
    }
  };

  return (
    (imageCategory === "OTHER" ||
      !localResourceImages?.some(
        (resourceImage) => resourceImage.imageCategory === imageCategory
      )) && (
      <>
        <Paper
          sx={{
            minWidth: "100px",
            width: "100px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "4px",
            paddingBlock: "15px",
            boxSizing: "border-box",
            cursor: "pointer",
          }}
          onClick={handleImageAddClick}
        >
          <Box>
            {getSvgIcon(
              "CREATE_NEW",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </Box>

          <Box>
            {
              <Typography sx={{ color: theme.palette.secondary.contrastText }}>
                {getTranslation(imageCategory, t, i18n)}
              </Typography>
            }
          </Box>
        </Paper>

        {openImageDialog && (
          <AddLocalImageDialog
            open={openImageDialog}
            setOpen={setOpenImageDialog}
            imageCategory={imageCategory}
            resourceId={mode === "edit-asset" ? calculatedResourceId : null}
          />
        )}
      </>
    )
  );
};

export default AddLocalImageButton;
