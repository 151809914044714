import { useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useOrientation from "../../../hooks/useOrientation";
import {
  useGetAllFunctionsQuery,
  useGetResourcesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  selectIndex,
  selectIsSearchOpen,
  selectPageView,
  selectSearchTerm,
  selectSortingOption,
  setIndex,
} from "../../../store/slices/appSlice";
import {
  selectItems,
  selectSearchResultItems,
  setItems,
  setSearchResultItems,
} from "../../../store/slices/assetListSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  CATEGORY_SELECT_LIST,
  getPageSize,
  getTranslation,
  RESOURCE_ITEM_HEIGHT,
  TABLET_LANDSCAPE_PAGE_SIZE,
  TABLET_PORTRAIT_PAGE_SIZE,
} from "../../../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import ErrorHandling from "../../common/ErrorHandling";
import { AssetListContainer } from "../../styles/assets/asset-list/AssetList.styles";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import {
  GroupByResourceCategoryContainer,
  NoDataTextContainer,
} from "../../styles/general/General.styles";
import ListGridView from "./ListGridView";
import ListInlineView from "./ListInlineView";
import { useTranslation } from "react-i18next";

const AssetListRenderer = ({ resourceid }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const currentpageview = useSelector(selectPageView);
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const sortingOption = useSelector(selectSortingOption);
  const searchTerm = useSelector(selectSearchTerm);
  const index = useSelector(selectIndex);
  const items = useSelector(selectItems);
  const searchResultItems = useSelector(selectSearchResultItems);
  const isSearchOpen = useSelector(selectIsSearchOpen);

  // Other variables
  const pageSize = mobileMatches
    ? getPageSize(RESOURCE_ITEM_HEIGHT)
    : orientation === "landscape"
    ? TABLET_LANDSCAPE_PAGE_SIZE
    : TABLET_PORTRAIT_PAGE_SIZE;

  // Queries
  const resourceParentsData = useGetResourcesQuery(
    {
      organizationId,
      index,
      size: pageSize,
      sortBy: sortingOption.type,
      order: sortingOption.order,
    },
    {
      skip: currentpageview === "column" || Boolean(resourceid),
    }
  );

  const resourceChildrenData = useGetResourcesQuery(
    {
      parentId: resourceid,
      organizationId,
      index,
      size: pageSize,
      sortBy: sortingOption.type,
      order: sortingOption.order,
    },
    {
      skip: currentpageview === "column" || !Boolean(resourceid),
    }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: resourcesData,
    isLoading: isLoadingResources,
    isError: isErrorResources,
  } = resourceid ? resourceChildrenData : resourceParentsData;

  // Handlers
  const fetchMoreData = () => {
    setTimeout(() => {
      dispatch(setIndex(index + 1));
    }, 1000);
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  // Effects
  useEffect(() => {
    if (items) {
      dispatch(
        setSearchResultItems(
          items?.filter((item) => {
            const { name, displayId } = item;
            const combination = displayId || name;

            return combination
              ?.toLowerCase()
              ?.includes(searchTerm?.toLowerCase());
          })
        )
      );
    } else {
      dispatch(setSearchResultItems([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, searchTerm]);

  useEffect(() => {
    if (resourcesData?.data) {
      if (index <= 1) {
        dispatch(setItems([...resourcesData.data]));
      } else {
        dispatch(
          setItems([
            ...items,
            ...resourcesData.data.filter(
              (r) => !items.some((i) => i.id === r?.id)
            ),
          ])
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesData?.data, index]);
  const resultItems = isSearchOpen ? searchResultItems : items;

  return (
    <ErrorHandling
      isLoading={isLoadingFunctions || isLoadingResources}
      isError={isErrorFunctions || isErrorResources}
    >
      <AssetListContainer
        isdesktop={desktopMatches}
        isappview={currentpageview === "app"}
      >
        {sortingOption.value === "CATEGORY" ? (
          CATEGORY_SELECT_LIST.sort((a, b) => a.label > b.label)
            .filter((category) => {
              const list = resultItems?.filter(
                (r) => getFunction(r.functionId)?.category === category.value
              );
              const hasItems = list?.length > 0;

              return hasItems;
            })
            .map((category) => {
              const list = resultItems?.filter(
                (r) => getFunction(r.functionId)?.category === category.value
              );

              return (
                <GroupByResourceCategoryContainer key={category.value}>
                  <SecondaryText>{category.label + "s"}</SecondaryText>
                  {currentpageview === "inline" ? (
                    <ListInlineView
                      list={list}
                      index={index}
                      fetchMoreData={fetchMoreData}
                      resourcesData={resourcesData}
                      pageSize={pageSize}
                    />
                  ) : (
                    <ListGridView
                      list={list}
                      index={index}
                      fetchMoreData={fetchMoreData}
                      resourcesData={resourcesData}
                      pageSize={pageSize}
                    />
                  )}
                </GroupByResourceCategoryContainer>
              );
            })
        ) : currentpageview === "inline" ? (
          <ListInlineView
            list={resultItems}
            index={index}
            fetchMoreData={fetchMoreData}
            resourcesData={resourcesData}
            pageSize={pageSize}
          />
        ) : (
          <ListGridView
            list={resultItems}
            index={index}
            fetchMoreData={fetchMoreData}
            resourcesData={resourcesData}
            pageSize={pageSize}
          />
        )}
      </AssetListContainer>
      {isSearchOpen &&
        resultItems?.length <= 0 &&
        resourcesData?.totalRecord > 0 && (
          <NoDataTextContainer>
            <SecondaryText align="center">
              {getTranslation("NO_RESULTS_FOUND", t, i18n)}
            </SecondaryText>
          </NoDataTextContainer>
        )}
    </ErrorHandling>
  );
};

export default AssetListRenderer;
