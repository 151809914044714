import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CATEGORY_SELECT_LIST,
  getTranslation,
  RESOURCE_CATEGORIES,
} from "../../../util/utils";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectResourceInput,
  setResourceInput,
} from "../../../store/slices/resourceInputSlice";
import { useCheckNameMutation } from "../../../store/slices/api/assetManagementSlice";
import {
  selectError,
  selectIsFirstSubmitted,
  setError,
} from "../../../store/slices/resourceInputSlice";
import { Box, Grid, Stack, TextField, useTheme } from "@mui/material";
import CharacteristicInputGroup from "./CharacteristicInputGroup";
import SelectInput from "../../SelectInput";
import FunctionInput from "./FunctionInput";
import TypeInput from "./TypeInput";
import { ValidationText } from "../../styles/inputs/NamesGroup.styles";
import { ParentInputWrapper } from "../../styles/assets/asset-form/AssetForm.styles";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { useEffect, useRef, useState } from "react";
import {
  BgAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import LocalResourceImagesList from "../asset-attachments/LocalResourceImagesList";
import LocalResourceAttachmentList from "../asset-attachments/LocalResourceAttachmentsList";
import TagsAssetFormSection from "./TagsAssetFormSection";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import ParentTreeInput from "./ParentTreeInput";
import { DISABLED_CHILDREN_CATEGORIES } from "../../../util/asset-utils";

export const LIFECYCLE_STATUSES = [
  { label: "Active", value: "ACTIVE" },
  { label: "Requested", value: "REQUESTED" },
  { label: "Planned", value: "PLANNED" },
  { label: "Failed", value: "FAILED" },
  { label: "Spare", value: "SPARE" },
  { label: "Decommissioned", value: "DECOMMISSIONED" },
  { label: "Unracked", value: "UNRACKED" },
  { label: "Recycled", value: "RECYCLED" },
];

const AssetForm = ({
  id,
  setOpenConfirm,
  setRedirectRoute,
  allFunctionsData,
  allResourcesData,
  mode,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const resourceInput = useSelector(selectResourceInput);
  const error = useSelector(selectError);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Mutations
  const [checkName] = useCheckNameMutation();

  // Refs
  const textRefType = useRef(null);
  const textRefBasicData = useRef(null);
  const iconRefType = useRef(null);
  const iconRefBasicData = useRef(null);

  // State
  const [borderWidthType, setBorderWidthType] = useState("25%");
  const [borderWidthBasicData, setBorderWidthBasicData] = useState("25%");

  // Other variables
  const leftPadding = 16;

  const {
    name,
    category,
    typeId,
    functionId,
    displayId,
    description,
    characteristics,
    hasTypeChanged,
  } = resourceInput;

  const sortedSelectCategoryList = CATEGORY_SELECT_LIST.sort((a, b) => {
    if (a.id > b.id) {
      return 1;
    } else {
      return -1;
    }
  });

  const iconSize = globalFontSize * 1.2;

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const getCategoryDisabledOptions = () => {
    const parent = allResourcesData?.find(
      (r) => r.id === resourceInput.parentId
    );

    const parentFunction = getFunction(parent?.functionId);
    const disabledChildrenCategories =
      DISABLED_CHILDREN_CATEGORIES[parentFunction?.category ?? ""];
    return disabledChildrenCategories.map((c) => ({
      value: c,
    }));
  };

  const categoryDisabledOptions = getCategoryDisabledOptions();

  // Handlers
  const handleChangeName = (e) => {
    const newName = e.target.value.trimStart();

    if (error.name) {
      dispatch(setError({}));
    }

    dispatch(setResourceInput({ ...resourceInput, name: newName }));
  };

  const handleChangeResourceCategory = (value) => {
    const newResourceCategory = value;

    dispatch(
      setResourceInput({
        ...resourceInput,
        category: newResourceCategory,
        functionId: null,
        typeId: null,
        hasTypeChanged: true,
      })
    );
  };

  const handleChangeFunctionId = (value) => {
    const newFunctionId = value;
    dispatch(
      setResourceInput({
        ...resourceInput,
        functionId: newFunctionId,
        typeId: null,
        hasTypeChanged: true,
        characteristics: [],
      })
    );
  };

  const handleChangeDisplayId = (e) => {
    const newDisplayId = e.target.value.trimStart();
    dispatch(setResourceInput({ ...resourceInput, displayId: newDisplayId }));
  };

  const handleChangeDescription = (e) => {
    const newDescription = e.target.value;
    dispatch(
      setResourceInput({ ...resourceInput, description: newDescription })
    );
  };

  const handleBlurName = async () => {
    try {
      const checkData = await checkName({
        name: resourceInput.name,
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default).id,
      }).unwrap();

      if (checkData) {
        if (!checkData.valid) {
          dispatch(
            setError({
              name: `${getTranslation("UNIQUE_NAME_VALIDATION", t, i18n)}: ${
                checkData.suggestion
              }`,
            })
          );
        }
      }
    } catch (error) {}
  };

  // Effects
  useEffect(() => {
    if (textRefType.current && iconRefType.current) {
      const textWidth = textRefType.current.offsetWidth;
      const totalWidth = textRefType.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefType.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthType(`${percentage}%`);
    }

    if (textRefBasicData.current && iconRefBasicData.current) {
      const textWidth = textRefBasicData.current.offsetWidth;
      const totalWidth = textRefBasicData.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefBasicData.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthBasicData(`${percentage}%`);
    }
  }, []);

  return (
    <Stack marginTop={2} rowGap={1}>
      {/* Basic data Accordion */}
      <BgAccordion defaultExpanded>
        <StyledAccordionSummary
          value={borderWidthBasicData}
          expandIcon={
            <div
              style={{ marginTop: "5px", marginRight: "5px" }}
              ref={iconRefBasicData}
            >
              {getSvgIcon(
                "ARROW_RIGHT",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </div>
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <SectionTitle variant="body2" istablet={1} ref={textRefBasicData}>
            {getTranslation("BASIC_DATA", t, i18n)}
          </SectionTitle>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              {/* Resource Name input */}
              <Box>
                <TextField
                  inputProps={{
                    "data-testid": "name-input",
                  }}
                  data-testid="name"
                  required={true}
                  label={getTranslation("NAME", t, i18n)}
                  value={name}
                  onChange={handleChangeName}
                  onBlur={handleBlurName}
                  error={(isFirstSubmitted && !name) || error.name}
                />
                {error.name && <ValidationText>{error.name}</ValidationText>}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Resource DisplayId input */}
              <Box>
                <TextField
                  inputProps={{
                    "data-testid": "display-id-input",
                  }}
                  data-testid="display-id"
                  label={getTranslation("DISPLAY_ID", t, i18n)}
                  value={displayId}
                  onChange={handleChangeDisplayId}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Resource Parent input */}
              <ParentInputWrapper>
                <ParentTreeInput
                  resourceId={id}
                  category={category}
                  parentId={resourceInput.parentId}
                />
              </ParentInputWrapper>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Resource Description input */}
              <Box>
                <TextField
                  inputProps={{
                    "data-testid": "notes-input",
                  }}
                  data-testid="notes"
                  label={getTranslation("NOTES", t, i18n)}
                  value={description}
                  onChange={handleChangeDescription}
                  multiline
                  rows={3}
                />
              </Box>
            </Grid>
          </Grid>
        </StyledAccordionDetails>
      </BgAccordion>

      {/* Type Accordion */}
      <BgAccordion defaultExpanded>
        <StyledAccordionSummary
          value={borderWidthType}
          expandIcon={
            <div
              style={{ marginTop: "5px", marginRight: "5px" }}
              ref={iconRefType}
            >
              {getSvgIcon(
                "ARROW_RIGHT",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </div>
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <SectionTitle variant="body2" istablet={1} ref={textRefType}>
            {getTranslation("TYPE", t, i18n)}
          </SectionTitle>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              {/* Resource Type input */}
              <Box>
                <SelectInput
                  fullWidth
                  selectLabelId="resourceCategory-label"
                  label={getTranslation("RESOURCE_CATEGORY", t, i18n)}
                  handleChange={handleChangeResourceCategory}
                  data={sortedSelectCategoryList}
                  value={category}
                  required={true}
                  error={isFirstSubmitted && !resourceInput.category}
                  disabledOptions={categoryDisabledOptions}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Resource Function input */}
              <Box>
                <FunctionInput
                  required={true}
                  isFirstSubmitted={isFirstSubmitted}
                  changeHandler={handleChangeFunctionId}
                  functionId={functionId}
                  category={category}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Resource Type input  */}
              <Box>
                <TypeInput
                  required={
                    Boolean(resourceInput.category) &&
                    resourceInput.category !== RESOURCE_CATEGORIES.LOCATION
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </StyledAccordionDetails>
      </BgAccordion>

      {/* Resource Characteristics */}
      {functionId && (
        <Box>
          <CharacteristicInputGroup
            characteristics={characteristics}
            functionId={functionId}
            typeId={typeId}
            hasTypeChanged={hasTypeChanged}
          />
        </Box>
      )}

      {/* Images */}
      <LocalResourceImagesList mode={mode} />

      {/* Attachments */}
      <LocalResourceAttachmentList mode={mode} />

      {/* Tags */}
      {mode === "edit-asset" && (
        <TagsAssetFormSection
          id={id}
          setOpenConfirm={setOpenConfirm}
          setRedirectRoute={setRedirectRoute}
        />
      )}

      <div id="end"></div>
    </Stack>
  );
};

export default AssetForm;
