import { useEffect, useMemo, useState, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SelectInput from "../../SelectInput";
import { useTranslation } from "react-i18next";
import {
  CATEGORY_SELECT_LIST,
  getTranslation,
  RESOURCE_CATEGORIES,
  showValidationError,
  transitionDirections,
} from "../../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectGlobalFontSize,
  selectPageInfo,
  selectPageView,
  selectTheme,
  setIsActionButtonDisabled,
} from "../../../store/slices/appSlice";
import {
  resetResourceInput,
  selectError,
  selectIsFirstSubmitted,
  selectOriginalInput,
  selectResourceInput,
  setError,
  setIsFirstSubmitted,
  setOriginalInput,
  setResourceInput,
} from "../../../store/slices/resourceInputSlice";
import {
  useCheckNameMutation,
  useCreateResourceMutation,
  useGetAllFunctionsQuery,
  useGetAllResourcesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { ValidationText } from "../../styles/inputs/NamesGroup.styles";
import { messageError, messageSuccess } from "../../../util/notification";
import {
  DISABLED_CHILDREN_CATEGORIES,
  checkResourceChanged,
  transformResourceInputRequest,
  validateResourceForm,
} from "../../../util/asset-utils";
import {
  clearList,
  selectResourceImages,
} from "../../../store/slices/resourceImageSlice";
import {
  clearAttachmentList,
  selectResourceAttachments,
} from "../../../store/slices/resourceAttachmentSlice";
import { useNavigate } from "react-router-dom";
import { useUploadResourceImageMutation } from "../../../store/slices/api/resourceImagesApiSlice";
import { useUploadResourceAttachmentMutation } from "../../../store/slices/api/resourceAttachmentsApiSlice";
import CharacteristicInputGroup from "./CharacteristicInputGroup";
import TypeInput from "./TypeInput";
import FunctionInput from "./FunctionInput";
import {
  CancelButton,
  CreateButton,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import ErrorHandling from "../../common/ErrorHandling";
import { DialogPaperProps } from "../../styles/general/General.styles";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { ParentInputWrapper } from "../../styles/assets/asset-form/AssetForm.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import LocalResourceImagesList from "../asset-attachments/LocalResourceImagesList";
import LocalResourceAttachmentList from "../asset-attachments/LocalResourceAttachmentsList";
import { getSvgIcon } from "../../../util/icons";
import ParentTreeInput from "./ParentTreeInput";

const CreateAsset = ({
  initializedParentId,
  initializedCategory,
  open,
  handleClose,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const resourceInput = useSelector(selectResourceInput);
  const originalInput = useSelector(selectOriginalInput);
  const resourceImages = useSelector(selectResourceImages);
  const resourceAttachments = useSelector(selectResourceAttachments);
  const error = useSelector(selectError);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const pageInfo = useSelector(selectPageInfo);
  const pageView = useSelector(selectPageView);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // Refs
  const textRefType = useRef(null);
  const textRefBasicData = useRef(null);
  const iconRefType = useRef(null);
  const iconRefBasicData = useRef(null);

  // States
  const [openDiscard, setOpenDiscard] = useState(false);
  const [borderWidthType, setBorderWidthType] = useState("25%");
  const [borderWidthBasicData, setBorderWidthBasicData] = useState("25%");

  // Queries
  const {
    data: allResourcesData,
    isLoading: isLoadingResources,
    isError: isErrorResources,
  } = useGetAllResourcesQuery({
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [createResource, { isLoading: isLoadingCreate }] =
    useCreateResourceMutation();
  const [checkName] = useCheckNameMutation();
  const [uploadResourceImage, { isLoading: isLoadingImage }] =
    useUploadResourceImageMutation();
  const [uploadResourceAttachment, { isLoading: isLoadingAttachment }] =
    useUploadResourceAttachmentMutation();

  // Other variables
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  const {
    id,
    name,
    displayId,
    description,
    functionId,
    category,
    characteristics,
    typeId,
    hasTypeChanged,
  } = resourceInput;

  const checkHasChanged = useMemo(
    () => checkResourceChanged(originalInput, resourceInput, user.region),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceInput, originalInput]
  );

  const alert = {
    title: getTranslation("CANCEL_CREATE", t, i18n),
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const sortedSelectCategoryList = CATEGORY_SELECT_LIST.sort((a, b) => {
    if (a.id > b.id) {
      return 1;
    } else {
      return -1;
    }
  });

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const getCategoryDisabledOptions = () => {
    const parent = allResourcesData?.find(
      (r) => r.id === resourceInput.parentId
    );

    const parentFunction = getFunction(parent?.functionId);
    const disabledChildrenCategories =
      DISABLED_CHILDREN_CATEGORIES[parentFunction?.category ?? ""];
    return disabledChildrenCategories.map((c) => ({
      value: c,
    }));
  };

  const categoryDisabledOptions = getCategoryDisabledOptions();

  // Handlers
  const handleSubmit = async () => {
    dispatch(setIsFirstSubmitted(true));

    const parent = allResourcesData?.find(
      (r) => r.id === resourceInput.parentId
    );
    const parentFunction = getFunction(parent?.functionId);

    const isParentHardwareAsset =
      parentFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET;
    const isParentRack = parentFunction?.category === RESOURCE_CATEGORIES.RACK;
    const hasLocations =
      allResourcesData?.filter((r) => {
        return (
          getFunction(r.functionId)?.category === RESOURCE_CATEGORIES.LOCATION
        );
      })?.length > 0;

    if (
      resourceInput.category === RESOURCE_CATEGORIES.HARDWARE_ASSET ||
      resourceInput.category === RESOURCE_CATEGORIES.RACK
    ) {
      if (!hasLocations) {
        messageError(getTranslation("NO_LOCATIONS_VALIDATION", t, i18n));
        return;
      }
    }

    if (
      resourceInput.category === RESOURCE_CATEGORIES.LOCATION &&
      resourceInput.parentId &&
      (isParentHardwareAsset || isParentRack)
    ) {
      messageError(getTranslation("CHANGE_PARENT_VALIDATION", t, i18n));
      return;
    }

    if (
      resourceInput.category === RESOURCE_CATEGORIES.RACK &&
      resourceInput.parentId &&
      isParentHardwareAsset
    ) {
      messageError(
        getTranslation("CHANGE_HARDWARE_PARENT_VALIDATION", t, i18n)
      );
      return;
    }

    const { error: evaluatedError, firstError } = validateResourceForm(
      resourceInput,
      t
    );

    const isValid =
      Object.keys(evaluatedError).length === 0 && !Boolean(error.name);
    if (!isValid) {
      if (firstError) {
        messageError(firstError);
      }

      if (error.name) {
        messageError(error.name);
      }
      dispatch(setIsActionButtonDisabled(true));
      return;
    }

    try {
      // Creates resource
      const resource = await createResource({
        resourceInput: transformResourceInputRequest(
          resourceInput,
          user?.region
        ),
        organizationId: user?.organizations?.find((o) => o.default).id,
        category: resourceInput.category.replace("_", "-").toLowerCase(),
      }).unwrap();

      // Upload resource images
      for (let i = 0; i < resourceImages.length; i++) {
        const resourceImage = resourceImages[i];
        const { imageCategory, file, name, description } = resourceImage;

        const formData = new FormData();

        if (file) {
          formData.append("file", file);
          formData.append("fileName", name);
          formData.append("imageCategory", imageCategory);
          formData.append("description", description);
        }

        await uploadResourceImage({
          formData,
          resourceid: resource.id,
          organizationId: user?.organizations?.find((o) => o.default).id,
        }).unwrap();
      }

      // Upload resource attachments
      for (let i = 0; i < resourceAttachments.length; i++) {
        const resourceAttachment = resourceAttachments[i];
        const { file, name: fileName, description } = resourceAttachment;

        const formData = new FormData();

        if (file) {
          formData.append("file", file);
          formData.append("fileName", fileName);
          formData.append("description", description);
        }

        await uploadResourceAttachment({
          formData: file ? formData : { msg: "Create without file" },
          resourceid: resource.id,
          organizationId: user?.organizations?.find((o) => o.default).id,
        }).unwrap();
      }

      if (pageView !== "column") {
        navigate(
          `/resources${
            resourceInput.parentId ? `/${resourceInput.parentId}` : ""
          }?direction=${transitionDirections.TOP_TO_BOTTOM}`
        );
      }

      messageSuccess(getTranslation("ASSET_CREATED_SUCCESSFULLY", t, i18n));
      handleClose();
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to create a resource", error);
    }
  };

  const handleChangeName = (e) => {
    const newName = e.target.value.trimStart();

    dispatch(
      setResourceInput({
        ...resourceInput,
        name: newName,
      })
    );
  };

  const handleBlurName = async () => {
    try {
      const checkData = await checkName({
        name: resourceInput.name,
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default).id,
      }).unwrap();

      let error = {};
      if (checkData) {
        if (!checkData.valid) {
          error = {
            name: `${getTranslation("UNIQUE_NAME_VALIDATION", t, i18n)}: ${
              checkData.suggestion
            }`,
          };
        }
      }

      dispatch(setError(error));
    } catch (error) {}
  };

  const handleChangeResourceCategory = (value) => {
    const newResourceCategory = value;

    dispatch(
      setResourceInput({
        ...resourceInput,
        category: newResourceCategory,
        functionId: null,
        typeId: null,
      })
    );
  };

  const handleChangeFunctionId = (value) => {
    const newFunctionId = value;
    dispatch(
      setResourceInput({
        ...resourceInput,
        functionId: newFunctionId,
        typeId: null,
        characteristics: [],
      })
    );
  };

  const handleChangeDisplayId = (e) => {
    const newDisplayId = e.target.value.trimStart();
    dispatch(setResourceInput({ ...resourceInput, displayId: newDisplayId }));
  };

  const handleChangeDescription = (e) => {
    const newDescription = e.target.value;
    dispatch(
      setResourceInput({ ...resourceInput, description: newDescription })
    );
  };

  const handleCancelCreate = () => {
    if (checkResourceChanged(originalInput, resourceInput, user.region)) {
      setOpenDiscard(true);
      return;
    }

    handleClose();
  };

  const handleDialogRef = (node) => {
    if (textRefType.current && iconRefType.current) {
      const textWidth = textRefType.current.offsetWidth;
      const totalWidth = textRefType.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefType.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthType(`${percentage}%`);
    }

    if (textRefBasicData.current && iconRefBasicData.current) {
      const textWidth = textRefBasicData.current.offsetWidth;
      const totalWidth = textRefBasicData.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefBasicData.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthBasicData(`${percentage}%`);
    }
  };

  // Effects
  useEffect(() => {
    if (open) {
      dispatch(resetResourceInput());
      dispatch(clearList());
      dispatch(clearAttachmentList());
    }

    return () => {
      // Reset resource contents
      dispatch(resetResourceInput());
      dispatch(clearList());
      dispatch(clearAttachmentList());
      setOpenDiscard(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateResourceForm(resourceInput, t);
    const isValid =
      Object.keys(evaluatedError).length === 0 &&
      !error.name &&
      !error.functionId;

    dispatch(setIsActionButtonDisabled(!isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInput, error]);

  useEffect(() => {
    if (open) {
      const initialResourceInput = {
        ...resourceInput,
        parentId: initializedParentId,
        category: initializedCategory,
      };

      dispatch(setResourceInput(initialResourceInput));
      dispatch(setOriginalInput(initialResourceInput));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, initializedParentId, initializedCategory]);

  useEffect(() => {
    if (open) {
      if (textRefType.current && iconRefType.current) {
        const textWidth = textRefType.current.offsetWidth;
        const totalWidth = textRefType.current.parentElement.offsetWidth;

        const percentage =
          ((textWidth +
            leftPadding +
            iconRefType.current.width?.animVal?.value) /
            totalWidth) *
          100;

        setBorderWidthType(`${percentage}%`);
      }

      if (textRefBasicData.current && iconRefBasicData.current) {
        const textWidth = textRefBasicData.current.offsetWidth;
        const totalWidth = textRefBasicData.current.parentElement.offsetWidth;

        const percentage =
          ((textWidth +
            leftPadding +
            iconRefBasicData.current.width?.animVal?.value) /
            totalWidth) *
          100;

        setBorderWidthBasicData(`${percentage}%`);
      }
    }
  }, [open]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingAttachment ||
        isLoadingCreate ||
        isLoadingFunctions ||
        isLoadingImage ||
        isLoadingResources
      }
      isError={isErrorFunctions || isErrorResources}
    >
      <>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          PaperProps={DialogPaperProps}
          open={open}
          onClose={handleCancelCreate}
          ref={handleDialogRef}
        >
          {tabletMatches && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  sx={{ color: theme.palette.secondary.contrastText }}
                  variant="text"
                  onClick={handleCancelCreate}
                >
                  {getTranslation("CANCEL", t, i18n)}
                </Button>
              </Box>

              <Box>
                <DialogTitle>
                  {getTranslation("NEW_ASSET", t, i18n)}
                </DialogTitle>
              </Box>

              <Box>
                <Button
                  sx={{ color: theme.palette.secondary.contrastText }}
                  variant="text"
                  disabled={
                    !checkHasChanged ||
                    pageInfo.isActionButtonDisabled ||
                    !resourceInput?.name ||
                    !resourceInput?.functionId ||
                    ((resourceInput.category ===
                      RESOURCE_CATEGORIES.HARDWARE_ASSET ||
                      resourceInput.category === RESOURCE_CATEGORIES.RACK) &&
                      !resourceInput.typeId) ||
                    isLoadingCreate ||
                    isLoadingImage ||
                    isLoadingAttachment
                  }
                  onClick={handleSubmit}
                >
                  {getTranslation("CREATE", t, i18n)}
                </Button>
              </Box>
            </Box>
          )}

          {desktopMatches && (
            <DialogTitle>{getTranslation("CREATE_ASSET", t, i18n)}</DialogTitle>
          )}
          <DialogContent>
            <ConfirmAlert
              isOpen={openDiscard}
              setIsOpen={setOpenDiscard}
              alert={alert}
              label="discard-changes"
              handleConfirm={handleClose}
            />

            {/* Basic data Accordion */}
            <StyledAccordion currentTheme={currentTheme} defaultExpanded>
              <StyledAccordionSummary
                value={borderWidthBasicData}
                expandIcon={
                  <div
                    style={{ marginTop: "5px", marginRight: "5px" }}
                    ref={iconRefBasicData}
                  >
                    {getSvgIcon(
                      "ARROW_RIGHT",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <SectionTitle
                  variant="body2"
                  istablet={1}
                  ref={textRefBasicData}
                >
                  {getTranslation("BASIC_DATA", t, i18n)}
                </SectionTitle>
              </StyledAccordionSummary>

              <StyledAccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    {/* Resource Name input */}
                    <Box>
                      <TextField
                        inputProps={{
                          "data-testid": "name-input",
                        }}
                        data-testid="name"
                        required={true}
                        label={getTranslation("NAME", t, i18n)}
                        value={name}
                        onChange={handleChangeName}
                        onBlur={handleBlurName}
                        error={(isFirstSubmitted && !name) || error.name}
                      />
                      {error.name && (
                        <ValidationText>{error.name}</ValidationText>
                      )}
                    </Box>

                    {/* Resource DisplayId input */}
                    <Box>
                      <TextField
                        inputProps={{
                          "data-testid": "display-id-input",
                        }}
                        data-testid="display-id"
                        label={getTranslation("DISPLAY_ID", t, i18n)}
                        value={displayId}
                        onChange={handleChangeDisplayId}
                      />
                    </Box>

                    {/* Resource Parent input */}
                    <ParentInputWrapper>
                      <ParentTreeInput
                        category={category}
                        parentId={resourceInput.parentId}
                      />
                    </ParentInputWrapper>
                  </Grid>

                  <Grid item xs={6}>
                    {/* Resource Description input */}
                    <Box>
                      <TextField
                        inputProps={{
                          "data-testid": "notes-input",
                        }}
                        data-testid="notes"
                        label={getTranslation("NOTES", t, i18n)}
                        value={description}
                        onChange={handleChangeDescription}
                        multiline
                        rows={7.5}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </StyledAccordionDetails>
            </StyledAccordion>

            {/* Type Accordion */}
            <StyledAccordion currentTheme={currentTheme} defaultExpanded>
              <StyledAccordionSummary
                value={borderWidthType}
                expandIcon={
                  <div
                    style={{ marginTop: "5px", marginRight: "5px" }}
                    ref={iconRefType}
                  >
                    {getSvgIcon(
                      "ARROW_RIGHT",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <SectionTitle variant="body2" istablet={1} ref={textRefType}>
                  {getTranslation("TYPE", t, i18n)}
                </SectionTitle>
              </StyledAccordionSummary>

              <StyledAccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    {/* Resource Type input */}
                    <Box>
                      <SelectInput
                        fullWidth
                        selectLabelId="resourceCategory-label"
                        label={getTranslation("RESOURCE_CATEGORY", t, i18n)}
                        handleChange={handleChangeResourceCategory}
                        data={sortedSelectCategoryList}
                        value={category}
                        required={true}
                        error={isFirstSubmitted && !resourceInput.category}
                        disabledOptions={categoryDisabledOptions}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* Resource Function input */}
                    <Box>
                      <FunctionInput
                        required={true}
                        isFirstSubmitted={isFirstSubmitted}
                        changeHandler={handleChangeFunctionId}
                        functionId={functionId}
                        category={category}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    {/* Resource Type input  */}
                    <Box>
                      <TypeInput
                        required={
                          Boolean(resourceInput.category) &&
                          resourceInput.category !==
                            RESOURCE_CATEGORIES.LOCATION
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </StyledAccordionDetails>
            </StyledAccordion>

            {/* Resource Characteristics */}
            {functionId && (
              <Box>
                <CharacteristicInputGroup
                  characteristics={characteristics}
                  functionId={functionId}
                  typeId={typeId}
                  hasTypeChanged={hasTypeChanged}
                />
              </Box>
            )}

            {/* Images */}
            <LocalResourceImagesList mode="create-asset" />

            {/* Attachments */}
            <LocalResourceAttachmentList mode="create-asset" />
          </DialogContent>

          {desktopMatches && (
            <DialogActions>
              <CancelButton variant="outlined" onClick={handleCancelCreate}>
                {getTranslation("CANCEL", t, i18n)}
              </CancelButton>

              <CreateButton
                disabled={
                  !checkHasChanged ||
                  pageInfo.isActionButtonDisabled ||
                  !resourceInput?.name ||
                  !resourceInput?.functionId ||
                  ((resourceInput.category ===
                    RESOURCE_CATEGORIES.HARDWARE_ASSET ||
                    resourceInput.category === RESOURCE_CATEGORIES.RACK) &&
                    !resourceInput.typeId) ||
                  isLoadingCreate ||
                  isLoadingImage ||
                  isLoadingAttachment
                }
                onClick={handleSubmit}
              >
                {getTranslation("CREATE", t, i18n)}
              </CreateButton>
            </DialogActions>
          )}
        </Dialog>
      </>
    </ErrorHandling>
  );
};

export default CreateAsset;
