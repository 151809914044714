import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import TypeListInlineView from "../../components/types/type-list/TypeListInlineView";
import TypeStickySearch from "../../components/types/type-list/TypeStickySearch";
import PageTransition from "../../components/PageTransition";
import {
  CreateAssetButton,
  NoTypesContainer,
} from "../../components/styles/assets/AssetList.styles";
import { SecondaryText } from "../../components/styles/assets/ListInlineView.styles";
import {
  HomePagePadding,
  SecondaryContrastButton,
} from "../../components/styles/general/General.styles";
import {
  DEFAULT_INDEX,
  selectGlobalFontSize,
} from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import {
  selectTypeQuery,
  selectCategory,
  selectFunction,
  setCategory,
  setFunction,
  selectIndex,
  setIndex,
  TYPES_SORTING_OPTIONS,
  TYPE_DEFAULT_SORTING_OPTION,
} from "../../store/slices/typeSearchSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import {
  getPageSize,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_ITEM_HEIGHT,
  transitionDirections,
  TYPE_LANDSCAPE_PAGE_SIZE,
  TYPE_PORTRAIT_PAGE_SIZE,
} from "../../util/utils";
import LibraryHeader from "../../navigation/header/library/LibraryHeader";
import { useGetTypesWithPaginationQuery } from "../../store/slices/api/typesApiSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import DesktopTypeStickySearch from "../../components/types/type-list/DesktopTypeStickySearch";
import CreateType from "../../components/types/type-form/CreateType";
import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import DesktopTypeDetails from "../../components/types/type-detail/DesktopTypeDetails";
import {
  FirstScreenContainer,
  SplitScreen,
} from "../../components/styles/types/type-list/TypeListPage.styles";
import TypeAccordionSection from "../../components/types/type-list/TypeAccordionSection";
import useOrientation from "../../hooks/useOrientation";
import {
  selectItems,
  selectTypeId,
  setItems,
  setTypeId,
} from "../../store/slices/typeSlice";
import ErrorHandling from "../../components/common/ErrorHandling";
import CategoryTypeMenu from "../../components/types/type-form/CategoryTypeMenu";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";
import { getSvgIcon } from "../../util/icons";

const TypeListPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const theme = useTheme();

  // Custom hooks
  const orientation = useOrientation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const typeQuery = useSelector(selectTypeQuery);
  const category = useSelector(selectCategory);
  const functionId = useSelector(selectFunction);
  const allIndex = useSelector(selectIndex);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTypeId = useSelector(selectTypeId);
  const items = useSelector(selectItems);

  // States
  // mimic delay
  const [fetchingTypes, setFetchingTypes] = useState(false);
  const [allItemsTotalRecord, setAllItemsTotalRecord] = useState(0);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initializedCategory, setInitializedCategory] = useState("");
  const [sortingOption, setSortingOption] = useState(
    TYPE_DEFAULT_SORTING_OPTION
  );

  const pageSize = mobileMatches
    ? getPageSize(RESOURCE_ITEM_HEIGHT)
    : orientation === "landscape"
    ? TYPE_LANDSCAPE_PAGE_SIZE
    : TYPE_PORTRAIT_PAGE_SIZE;

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isFetching: isFetchingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId,
  });

  const {
    data: typesData,
    isLoading: isLoadingTypes,
    isFetching: isFetchingTypes,
    isError: isErrorTypes,
  } = useGetTypesWithPaginationQuery(
    {
      functionId,
      organizationId,
      index: allIndex,
      size: pageSize,
      sortBy: sortingOption.type,
      order: sortingOption.order,
      query: typeQuery,
    },
    { skip: !Boolean(functionId) }
  );
  // Other variables
  const direction = searchParams.get("direction");
  const categoryParam = searchParams.get("category");
  const functionIdParam = searchParams.get("functionId");

  const openCategoryMenu = Boolean(anchorEl);
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleOpenCreateType = () => {
    setOpen(true);
  };

  const handleNavigateToCreateType = (category) =>
    navigate(
      `/library/add-new?direction=${transitionDirections.BOTTOM_TO_TOP}&category=${category}&previousPage=library`
    );

  const fetchMoreData = () => {
    setFetchingTypes(true);

    setTimeout(() => {
      dispatch(setIndex(allIndex + 1));
      setFetchingTypes(false);
    }, 1000);
  };

  const goBackHandler = () =>
    navigate(`/more?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  const handleOpenCategoryMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseCategoryMenu = (e) => {
    setAnchorEl(null);
  };

  const handleCreateType = (category) => {
    if (!mobileMatches) {
      setInitializedCategory(category);
      handleOpenCreateType();
    } else {
      handleNavigateToCreateType(category);
    }
  };

  // Effects
  useEffect(() => {
    dispatch(setIndex(DEFAULT_INDEX));

    if (categoryParam && functionIdParam) {
      dispatch(setCategory(categoryParam));
      dispatch(setFunction(functionIdParam));
    }

    return () => {
      dispatch(setTypeId(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!functionId || !category) {
      dispatch(setItems([]));
      setAllItemsTotalRecord(0);
      return;
    }

    if (typesData) {
      if (allIndex <= 1) {
        dispatch(setItems([...typesData.data]));
      } else {
        dispatch(
          setItems([
            ...items,
            ...typesData.data.filter((r) => !items.some((i) => i.id === r.id)),
          ])
        );
      }

      setAllItemsTotalRecord(typesData.totalRecord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typesData, category, functionId, allIndex]);

  useEffect(() => {
    dispatch(setIndex(DEFAULT_INDEX));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingOption, category, functionId, typeQuery]);

  useEffect(() => {
    setSortingOption(TYPE_DEFAULT_SORTING_OPTION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles, category, functionId]);

  return (
    <AppAccess>
      <Layer2Access>
        <ErrorHandling
          isLoading={
            isLoadingTypes ||
            isLoadingUserRoles ||
            isFetchingTypes ||
            isFetchingUserRoles
          }
          isError={isErrorTypes || isErrorUserRoles}
        >
          <PageTransition direction={direction}>
            <CategoryTypeMenu
              open={openCategoryMenu}
              handleClose={handleCloseCategoryMenu}
              categoryActionHandler={handleCreateType}
              anchorEl={anchorEl}
            />

            {!mobileMatches ? (
              <>
                <CreateType
                  open={open}
                  setOpen={setOpen}
                  category={initializedCategory}
                />

                <Grid container>
                  <FirstScreenContainer item lg={6} xl={5}>
                    <DesktopTypeStickySearch
                      sortingOption={sortingOption}
                      setSortingOption={setSortingOption}
                    />
                    {items?.length <= 0 ? (
                      <NoTypesContainer>
                        <SecondaryText align="center">
                          {getTranslation("NO_LIBRARY", t, i18n)}
                        </SecondaryText>
                        <CreateAssetButton
                          disabled={
                            !hasAccess(
                              "all",
                              [
                                permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                                permissions.ASSET_MANAGEMENT_LOCATION_ADD,
                                permissions.ASSET_MANAGEMENT_RACK_ADD,
                              ],
                              getPermissionsFromUserRoles(userRoles)
                            )
                          }
                          onClick={handleOpenCategoryMenu}
                        >
                          <div style={{ marginRight: "5px", marginTop: "5px" }}>
                            {getSvgIcon(
                              "CREATE_NEW",
                              iconSize,
                              iconSize,
                              theme.palette.primary.contrastText
                            )}
                          </div>

                          {getTranslation("CREATE_FIRST_TYPE", t, i18n)}
                        </CreateAssetButton>
                      </NoTypesContainer>
                    ) : (
                      <Stack spacing={5}>
                        <TypeAccordionSection
                          sectionName={getTranslation("ALL", t, i18n)}
                          endAdornment={
                            <SecondaryContrastButton
                              onClick={handleOpenCategoryMenu}
                              disabled={
                                !hasAccess(
                                  "all",
                                  [permissions.ASSET_MANAGEMENT_TYPE_ADD],
                                  getPermissionsFromUserRoles(userRoles)
                                )
                              }
                            >
                              <div style={{ marginRight: "5px" }}>
                                {getSvgIcon(
                                  "CREATE_NEW",
                                  iconSize,
                                  iconSize,
                                  theme.palette.secondary.contrastText
                                )}
                              </div>

                              {getTranslation("CREATE_NEW_TYPE", t, i18n)}
                            </SecondaryContrastButton>
                          }
                          items={items}
                          totalRecord={allItemsTotalRecord}
                          pageSize={pageSize}
                          index={allIndex}
                          isFetchingData={isFetchingTypes || fetchingTypes}
                          fetchMoreDataHandler={fetchMoreData}
                          prefixLabelItem={"all"}
                        />
                      </Stack>
                    )}
                  </FirstScreenContainer>
                  <SplitScreen item lg={6} xl={7}>
                    {currentTypeId && (
                      <DesktopTypeDetails typeId={currentTypeId} />
                    )}
                  </SplitScreen>
                </Grid>
              </>
            ) : (
              <>
                <LibraryHeader
                  goBackHandler={goBackHandler}
                  userRoles={userRoles}
                  actionAddFunction={handleNavigateToCreateType}
                />
                <HomePagePadding>
                  <TypeStickySearch
                    sortingOptions={TYPES_SORTING_OPTIONS}
                    sortingOption={sortingOption}
                    setSortingOption={setSortingOption}
                  />
                  <TypeListInlineView
                    list={items}
                    index={allIndex}
                    fetchMoreData={fetchMoreData}
                    totalRecord={allItemsTotalRecord}
                    pageSize={pageSize}
                  />
                  {items?.length <= 0 && (
                    <NoTypesContainer>
                      <SecondaryText align="center">
                        {getTranslation("NO_LIBRARY", t, i18n)}
                      </SecondaryText>
                      <CreateAssetButton
                        disabled={
                          !hasAccess(
                            "all",
                            [
                              permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                              permissions.ASSET_MANAGEMENT_LOCATION_ADD,
                              permissions.ASSET_MANAGEMENT_RACK_ADD,
                            ],
                            getPermissionsFromUserRoles(userRoles)
                          )
                        }
                        onClick={handleOpenCategoryMenu}
                      >
                        <div style={{ marginRight: "5px", marginTop: "5px" }}>
                          {getSvgIcon(
                            "CREATE_NEW",
                            iconSize,
                            iconSize,
                            theme.palette.primary.contrastText
                          )}
                        </div>

                        {getTranslation("CREATE_FIRST_TYPE", t, i18n)}
                      </CreateAssetButton>
                    </NoTypesContainer>
                  )}
                </HomePagePadding>
              </>
            )}
          </PageTransition>
        </ErrorHandling>
      </Layer2Access>
    </AppAccess>
  );
};

export default TypeListPage;
