import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePWAInstall } from "react-use-pwa-install";
import { THEME, getTranslation, userLocalStorageKey } from "../../util/utils";
import { selectGlobalFontSize, selectTheme } from "../../store/slices/appSlice";
import { useGetUserProfileImageQuery } from "../../store/slices/api/userManagementSlice";
import {
  styled,
  Toolbar,
  AppBar as MuiAppBar,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  useMediaQuery,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  CenteredStack,
  StyledGrowingStack,
} from "../../components/styles/navigation/drawer/DesktopDrawer.styles";
import OrganizationMenuItem from "./OrganizationMenuItem";
import {
  ActiveOrganizationMenuItem,
  LeftSpacedSecondaryText,
  ManageOrganizationsButton,
  ManageOrganizationsButtonBoxContainer,
  ManageOrganizationsMenuItem,
  MenuItemText,
  OrganizationNameBoxContainer,
  ProfileMenuItemStyledAvatar,
  ProfilePreferencesMenuItem,
} from "../../components/styles/profile/ProfileDesktop.styles";
import { EllipseSecondaryText } from "../../components/styles/profile/ProfileCardItm.styles";
import EventBus, { EVENT_DISCARD_CHANGES } from "../../util/EventBus";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import KeycloakService from "../../services/KeycloakService";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import { StyledAvatar } from "../../components/styles/header/Header.styles";
import { useGetOrganizationLogoQuery } from "../../store/slices/api/organizationsApiSlice";
import { Logo } from "../styles/Drawer.styles";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { getSvgIcon } from "../../util/icons";
import { AIRFOCUS_URL } from "../../Constants";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.primary.contrastText,
}));

const paperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 50,
      height: 50,
      ml: -0.5,
      mr: 1,
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
    minWidth: "300px",
    maxWidth: "325px",
    px: 2,
  },
};

const morePaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 50,
      height: 50,
      ml: -0.5,
      mr: 1,
    },
    "& .MuiList-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiList-root .MuiMenuItem-root": {
      paddingLeft: "8px",
    },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
    minWidth: "150px",
    maxWidth: "175px",
    px: 1,
    padding: 0,
  },
};

const Navbar = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const install = usePWAInstall();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // Other variables
  const organizations = user?.organizations;
  const activeOrganization = organizations?.find((o) => o.default);
  const hasOrganizations = organizations && organizations.length > 0;

  // States
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [organizationAnchorEl, setOrganizationAnchorEl] = useState(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);

  const { data: selectedFile } = useGetUserProfileImageQuery(
    {
      avatarUri: user?.avatarUri,
    },
    {
      skip: !Boolean(user?.avatarUri),
    }
  );

  const { data: organizationLogo } = useGetOrganizationLogoQuery(
    {
      logoUri: activeOrganization?.logoUri,
    },
    {
      skip: !Boolean(activeOrganization?.logoUri),
    }
  );

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(activeOrganization);

  // Other variables
  const openAccount = Boolean(accountAnchorEl);
  const openOrganization = Boolean(organizationAnchorEl);
  const openMore = Boolean(moreAnchorEl);
  const iconSize = globalFontSize * 1.2;

  const showNavigation = hasOrganizations && !isRestricted;

  // Handlers
  const handleNavigate = (to) => {
    handleNavigation(to);
  };

  const handleNavigation = (route) => {
    if (EventBus.checkEventTypeExisting(EVENT_DISCARD_CHANGES)) {
      EventBus.dispatch(EVENT_DISCARD_CHANGES, { route });
    } else {
      navigate(route);
    }
  };

  const handleNavigateToHome = () => {
    if (showNavigation) {
      handleNavigate("/");
    }
  };

  const handleLogout = () => {
    KeycloakService.doLogout();
    localStorage.removeItem("keycloak-initialized");
    localStorage.removeItem(userLocalStorageKey("app-initialized"));
  };

  const handleClickAccount = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleCloseAccount = () => {
    setAccountAnchorEl(null);
  };

  const handleClickMore = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleClickOrganization = (event) => {
    setOrganizationAnchorEl(event.currentTarget);
  };

  const handleCloseOrganization = () => {
    setOrganizationAnchorEl(null);
  };

  const handleCloseMore = () => {
    setMoreAnchorEl(null);
  };

  return (
    <AppBar position="fixed" elevation={2}>
      <Toolbar>
        <StyledGrowingStack alignItems="center" direction="row" spacing={4}>
          {currentTheme === THEME.DARK ? (
            <Logo
              onClick={handleNavigateToHome}
              isDesktop={desktopMatches}
              src={`${process.env.PUBLIC_URL}/twinalize-logo-dark-theme.svg`}
              alt="home icon"
            />
          ) : (
            <Logo
              onClick={handleNavigateToHome}
              isDesktop={desktopMatches}
              src={`${process.env.PUBLIC_URL}/twinalize-logo-light-theme.svg`}
              alt="home icon"
            />
          )}
        </StyledGrowingStack>

        <CenteredStack direction="row" spacing={2}>
          {organizations?.length > 1 && (
            <StyledAvatar
              onClick={handleClickOrganization}
              id="organization-avatar"
              src={
                activeOrganization?.logoUri && organizationLogo
                  ? organizationLogo
                  : null
              }
            >
              {activeOrganization?.nickname
                ? activeOrganization?.nickname?.substring(0, 2)?.toUpperCase()
                : activeOrganization?.name?.substring(0, 2)?.toUpperCase()}
            </StyledAvatar>
          )}
          <Tooltip title={getTranslation("profile", t, i18n)}>
            {user?.avatarUri && selectedFile ? (
              <StyledAvatar
                onClick={handleClickAccount}
                id="avatar"
                src={selectedFile}
              >
                {user?.shortUsername}
              </StyledAvatar>
            ) : (
              <IconButton id="profile-menu-link" onClick={handleClickAccount}>
                {getSvgIcon(
                  "PROFILE",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </IconButton>
            )}
          </Tooltip>

          <Tooltip title={getTranslation("MORE", t, i18n)}>
            <IconButton id="more-menu-link" onClick={handleClickMore}>
              {getSvgIcon(
                "MORE",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </IconButton>
          </Tooltip>
        </CenteredStack>

        <Menu
          anchorEl={organizationAnchorEl}
          id="organization-menu"
          open={openOrganization}
          onClose={handleCloseOrganization}
          onClick={handleCloseOrganization}
          PaperProps={paperProps}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {organizations?.map((organization) => (
            <OrganizationMenuItem
              key={organization.id}
              organization={organization}
            />
          ))}

          <Divider />

          <ManageOrganizationsMenuItem
            id="manage-organization"
            onClick={() => handleNavigate("/profile/organization")}
          >
            <ManageOrganizationsButtonBoxContainer>
              <ManageOrganizationsButton variant="text">
                {getTranslation("MANAGE_ORGANIZATIONS", t, i18n)}
              </ManageOrganizationsButton>
            </ManageOrganizationsButtonBoxContainer>
          </ManageOrganizationsMenuItem>
        </Menu>

        <Menu
          anchorEl={accountAnchorEl}
          id="account-menu"
          open={openAccount}
          onClose={handleCloseAccount}
          onClick={handleCloseAccount}
          PaperProps={paperProps}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            id="profile"
            onClick={() => {
              handleNavigate("/profile");
              handleCloseAccount();
            }}
          >
            <ListItemAvatar>
              {user?.avatarUri && selectedFile ? (
                <Avatar id="profile-avatar" src={selectedFile}>
                  {user?.shortUsername}
                </Avatar>
              ) : (
                <ProfileMenuItemStyledAvatar id="profile-no-avatar">
                  {user?.firstName?.length > 0 && user?.lastName?.length > 0
                    ? user?.firstName[0]?.toUpperCase() +
                      user?.lastName[0]?.toUpperCase()
                    : ""}
                </ProfileMenuItemStyledAvatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <LeftSpacedSecondaryText id="profile-name">
                  {user?.firstName + " " + user?.lastName}
                </LeftSpacedSecondaryText>
              }
              secondary={
                <LeftSpacedSecondaryText variant="caption" id="profile-email">
                  {user?.email}
                </LeftSpacedSecondaryText>
              }
            />
          </MenuItem>

          <ActiveOrganizationMenuItem
            id="active-organization"
            onClick={() => {
              handleNavigate("/profile/organization");
              handleCloseAccount();
            }}
          >
            <ListItemIcon>
              {getSvgIcon("ORGANIZATION", iconSize, iconSize)}
            </ListItemIcon>

            <OrganizationNameBoxContainer>
              <Typography>{getTranslation("organization", t, i18n)}</Typography>

              <EllipseSecondaryText id="active-organization-name">
                {user?.organizations?.find((o) => o.default)?.nickname ||
                  user?.organizations?.find((o) => o.default)?.name}
              </EllipseSecondaryText>
            </OrganizationNameBoxContainer>
          </ActiveOrganizationMenuItem>

          <ProfilePreferencesMenuItem
            id="preferences"
            onClick={() => {
              handleNavigate("/profile/preferences");
              handleCloseAccount();
            }}
          >
            <ListItemIcon>
              {getSvgIcon("PREFERENCES", iconSize, iconSize)}
            </ListItemIcon>

            {getTranslation("PREFERENCES", t, i18n)}
          </ProfilePreferencesMenuItem>

          <ProfilePreferencesMenuItem id="logout" onClick={handleLogout}>
            <ListItemIcon>
              {getSvgIcon("LOG_OUT", iconSize, iconSize)}
            </ListItemIcon>

            {getTranslation("logOut", t, i18n)}
          </ProfilePreferencesMenuItem>
        </Menu>

        <Menu
          anchorEl={moreAnchorEl}
          id="more-menu"
          open={openMore}
          onClose={handleCloseMore}
          onClick={handleCloseMore}
          PaperProps={morePaperProps}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <ManageOrganizationsMenuItem
            id="community"
            onClick={() => {
              navigate("/community");
              handleCloseMore();
            }}
          >
            <ListItemIcon>
              {getSvgIcon("FORUM", iconSize, iconSize)}
            </ListItemIcon>

            {getTranslation("FORUM", t, i18n)}
          </ManageOrganizationsMenuItem>

          <Tooltip title={getTranslation("RAISE_FEATURE_REQUEST", t, i18n)}>
            <ManageOrganizationsMenuItem
              id="community"
              onClick={() => {
                window.open(AIRFOCUS_URL, "_blank");
                handleCloseMore();
              }}
            >
              <ListItemIcon>
                {getSvgIcon("RAISE_FEATURE_REQUEST", iconSize, iconSize)}
              </ListItemIcon>

              <MenuItemText>
                {getTranslation("RAISE_FEATURE_REQUEST", t, i18n)}
              </MenuItemText>
            </ManageOrganizationsMenuItem>
          </Tooltip>

          {install && (
            <ProfilePreferencesMenuItem id="install" onClick={install}>
              <ListItemIcon>
                {getSvgIcon("INSTALL", iconSize, iconSize)}
              </ListItemIcon>

              {getTranslation("INSTALL", t, i18n)}
            </ProfilePreferencesMenuItem>
          )}

          <ProfilePreferencesMenuItem
            id="about"
            onClick={() => {
              navigate("/about");
              handleCloseMore();
            }}
          >
            <ListItemIcon>
              {getSvgIcon("ABOUT", iconSize, iconSize)}
            </ListItemIcon>

            {getTranslation("ABOUT", t, i18n)}
          </ProfilePreferencesMenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
